import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import {
  CreatedProductData,
  ProductDataType,
  SavedProductData,
} from "src/models/product";
import { useStoreContext } from "./contexts";

interface ProductContextProps {
  selectedProductIds: string[];
  setSelectedProductIds: Dispatch<SetStateAction<string[]>>;
  productsData: ProductDataType[];
  setProductsData: Dispatch<SetStateAction<ProductDataType[]>>;
  selectedProductData: ProductDataType | null;
  setSelectedProductData: Dispatch<SetStateAction<ProductDataType | null>>;
  createdProductsCount: number;
  setCreatedProductsCount: Dispatch<SetStateAction<number>>;
  createdProducts: CreatedProductData[];
  setCreatedProducts: Dispatch<SetStateAction<CreatedProductData[]>>;
  savedProducts: SavedProductData[];
  setSavedProducts: Dispatch<SetStateAction<SavedProductData[]>>;
}

const ProductContext = createContext<ProductContextProps | null>(null);

interface ProductProviderProps {
  children: ReactNode;
}

export const useProducts = (): ProductContextProps => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProducts must be used within a ProductProvider");
  }
  return context;
};

export const ProductProvider: React.FC<ProductProviderProps> = ({
  children,
}) => {
  const { storeId } = useStoreContext();
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [productsData, setProductsData] = useState<ProductDataType[]>([]);
  const [selectedProductData, setSelectedProductData] =
    useState<ProductDataType | null>(null);
  const [createdProductsCount, setCreatedProductsCount] = useState(0);
  const [createdProducts, setCreatedProducts] = useState<CreatedProductData[]>(
    []
  );
  const [savedProducts, setSavedProducts] = useState<SavedProductData[]>([]);
  // const [productsToPublish, setProductsToPublish] = useState<number[]>([]);


  return (
    <ProductContext.Provider
      value={{
        selectedProductIds,
        setSelectedProductIds,
        productsData,
        setProductsData,
        selectedProductData,
        setSelectedProductData,
        createdProductsCount,
        setCreatedProductsCount,
        createdProducts,
        setCreatedProducts,
        savedProducts,
        setSavedProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
