import {
  createContext,
  useContext,
  useState,
  useMemo,
  PropsWithChildren,
  FC,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { getUserProfileData } from "src/services/automation.service";
import { UserProfile } from "src/models/user-profile";

interface UserContextType {
  profileInfoOpened: boolean;
  setProfileInfoOpened: React.Dispatch<React.SetStateAction<boolean>>;
  userData: UserProfile;
  isLoading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [profileInfoOpened, setProfileInfoOpened] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: userDataQuery,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["user-data"],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getUserProfileData(token);
    },
  });

  const userData: UserProfile = useMemo(() => {
    return isSuccess ? userDataQuery?.data : [];
  }, [userDataQuery?.data, isSuccess]);

  const value = useMemo(
    () => ({
      profileInfoOpened,
      setProfileInfoOpened,
      userData,
      isLoading,
    }),
    [profileInfoOpened, setProfileInfoOpened, userData, isLoading, isSuccess]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }

  return context;
};
