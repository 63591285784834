import { FC, memo, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Flex, Image } from "@mantine/core";
import { MenuTabs } from "src/modules/products-screens/components/menu/components/menu-tabs";
import logoImg from "src/assets/icons/logo-black.png";
import { MenuCloseIcon } from "./menu-close-icon";

interface DesktopMenuProps {
  opened: boolean;
  toggle: () => void;
}

gsap.registerPlugin(useGSAP);

export const DesktopMenu: FC<DesktopMenuProps> = memo(({ opened, toggle }) => {
  const logo = useRef<HTMLImageElement | null>(null);

  useGSAP(
    () => {
      gsap.fromTo(logo.current, { opacity: 0 }, { opacity: 1, duration: 3 });
    },
    { scope: logo, dependencies: [opened] }
  );

  return (
    <Flex
      id="menu"
      onClick={toggle}
      direction="column"
      justify="space-between"
      align="center"
      pos="fixed"
      h="100vh"
      bg="white"
      top={0}
      left={opened ? 0 : 30}
      style={{
        borderRight: "1px solid black",
        zIndex: `${opened ? 55 : 10}`,
        transition: "all 0.2s linear",
      }}
      className="w-0 min-[575px]:w-[180px] hidden min-[575px]:flex"
    >
      <Flex h="20%" align="center">
        <Image src={logoImg} alt="logo" w={80} ref={logo} />
      </Flex>

      <MenuTabs />

      <Flex
        onClick={toggle}
        pos="absolute"
        top="50%"
        right={0}
        h={35}
        w={35}
        justify="center"
        align="center"
        bg="white"
        style={{
          cursor: "pointer",
          transform: "translateX(50%) translateY(45%)",
          border: `${opened ? "1px solid black" : ""}`,
          borderRadius: 10,
        }}
      >
        <MenuCloseIcon opened={opened} />
      </Flex>
    </Flex>
  );
});
