import { FC, memo } from "react";
import { Burger, Flex, Image } from "@mantine/core";
import { MenuTabs } from "src/modules/products-screens/components/menu/components/menu-tabs";
import logoImg from "src/assets/icons/logo-black.png";

interface MobileMenuProps {
  opened: boolean;
  toggle: () => void;
}

export const MobileMenu: FC<MobileMenuProps> = memo(({ opened, toggle }) => {
  if (!opened) return null;

  return (
    <Flex
      direction="column"
      justify="space-between"
      align="center"
      pos="fixed"
      top={0}
      left={0}
      bg="white"
      style={{
        zIndex: 50,
        transition: "all 0.2s linear",
      }}
      className="max-[575px]:w-screen w-0 h-screen"
    >
      <Burger
        opened={opened}
        onClick={toggle}
        className="inline-block m-3 ml-auto min-[575px]:hidden z-[51]"
      />

      <Flex
        align="center"
        className="transition-opacity duration-[3000ms] opacity-100 h-[20%]"
      >
        <Image src={logoImg} alt="logo" w={125} />
      </Flex>

      <MenuTabs type="mobile" onLinkClick={toggle} />
    </Flex>
  );
});
