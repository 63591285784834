import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { WebSocketContextType } from './types/context-type';
import { WebSocketClient } from './web-socket-client';

const initialValue: WebSocketContextType = {
  websocketClient: {} as any,
  isConnected: false,
  messages: [],
  setMessages: (messages: any[]) => {},
};

const Context = createContext<WebSocketContextType>(initialValue);

export const WebSocketContext: FC<PropsWithChildren<{}>> = memo(
  ({ children }) => {
    const ref = useRef<WebSocketClient>();
    const [isConnected, setIsConnected] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const progressRef = useRef<{ [key: string]: number }>({});

    useEffect(() => {
      const instance = new WebSocketClient();
      ref.current = instance;

      const onOpen = (e: Event) => {
        setIsConnected(true);
        // Reset progress tracking on new connection
        progressRef.current = {};
      };

      const onMessage = (e: MessageEvent) => {
        try {
          const parsedData = JSON.parse(e.data);

          // Ignore ping and timeout messages
          if (
            parsedData.action === 'ping' ||
            parsedData.message === 'Endpoint request timed out'
          ) {
            return;
          }

          // Handle progress updates
          if (parsedData.progress !== undefined && parsedData.id) {
            const currentProgress = progressRef.current[parsedData.id] || 0;

            const newProgress = Math.min(
              100,
              Math.max(currentProgress, parsedData.progress)
            );

            // Only update if progress has increased
            if (newProgress > currentProgress) {
              progressRef.current[parsedData.id] = newProgress;

              // Update message with validated progress
              parsedData.progress = newProgress;

              setMessages((prev) => {
                // Find and update existing message for this ID
                const existingIndex = prev.findIndex(
                  (msg) => msg.id === parsedData.id
                );
                if (existingIndex >= 0) {
                  const updated = [...prev];
                  updated[existingIndex] = parsedData;
                  return updated;
                }
                // Add new message if not found
                return [...prev, parsedData];
              });
            }
          } else {
            // Handle non-progress messages
            console.log('New message: ', parsedData);
            setMessages((prev) => [...prev, parsedData]);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      const onClose = (e: CloseEvent) => {
        setIsConnected(false);
        // Keep progress data on disconnect to prevent progress going backwards on reconnect
      };

      const onError = (e: Event) => {
        console.error('WebSocket error:', e);
        setIsConnected(false);
      };

      instance.webSocketInstance.addEventListener('open', onOpen);
      instance.webSocketInstance.addEventListener('message', onMessage);
      instance.webSocketInstance.addEventListener('close', onClose);
      instance.webSocketInstance.addEventListener('error', onError);

      return () => {
        if (instance.webSocketInstance) {
          instance.webSocketInstance.close();
          instance.webSocketInstance.removeEventListener('open', onOpen);
          instance.webSocketInstance.removeEventListener('message', onMessage);
          instance.webSocketInstance.removeEventListener('close', onClose);
          instance.webSocketInstance.removeEventListener('error', onError);
        }
      };
    }, []);

    const contextValue = useMemo(
      () => ({
        websocketClient: ref.current as WebSocketClient,
        isConnected,
        messages,
        setMessages,
      }),
      [isConnected, messages]
    );

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  }
);

export const useWebsocketClient = () => useContext(Context);
