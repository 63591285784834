import { Button, Flex, Select, Modal, Loader } from '@mantine/core';
import { ChatInput } from '../products-screens/products-chat/components/chat-input';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getChatBot,
  onBordingStore,
  updateStore,
  updateStoreAPIKey,
} from 'src/services/onboarding.service';
import { ChatMessage } from '../products-screens/products-chat/components/chat-message';
import { AnimatedText } from '../landing-screens';
import { Fragment, useEffect, useRef, useState } from 'react';
import icon from '../../assets/icons/icons8-bot-96.png';
import './Onboarding.css';
import { useNavigate } from 'react-router-dom';
import { StepperComponent } from 'src/components/steppers/stepper';
import { ChatLoading } from 'src/components/ChatLoading';
interface StoreInfo {
  country: string;
  description: string;
  name: string;
}
interface StoreSelectionData {
  is_complete?: boolean;
  message?: string;
  actionPromptScreen?: boolean;
  store_info?: StoreInfo;
  dataType?: [string];
  data?: any;
}
const Onboarding = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [storeId, setStoreId] = useState<string>('');
  const [storeSelectionData, setStoreSelectionData] =
    useState<StoreSelectionData | null>(null);
  const [messages, setMessages] = useState<
    { message: string; type: 'income' | 'outcome' }[]
  >([]);
  console.log();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const {
    data: BotData,
    status: BotStatus,
    refetch,
  } = useQuery({
    queryKey: ['chatBotData'],
    queryFn: async () => {
      return getChatBot(await getAccessTokenSilently());
    },
  });
  const selectStore = async (storeId: string) => {
    try {
      const token = await getAccessTokenSilently();
      const store_id = parseInt(storeId);
      const { data, error } = await onBordingStore(token, store_id);
      if (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { message: error.message, type: 'income' },
        ]);
      } else {
        setStoreSelectionData(data.data);
        if (data?.data?.message) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { message: data?.data?.message, type: 'income' },
          ]);
        }
      }
    } catch (error) {
      console.error('Error selecting store:', error);
    }
  };
  const { mutate: selectStoreMutation, isPending: isStorePending } =
    useMutation({
      mutationFn: (storeId: string) => {
        return selectStore(storeId);
      },
    });
  const { mutate: selectStoreUpdateMutation, isPending: isStoreUpdatePending } =
    useMutation({
      mutationFn: (message: string) => {
        return updateStorehandler(message);
      },
    });
  const { mutate: updateApiKey, isPending: isUpdatePending } = useMutation({
    mutationFn: (APIKEY: string) => {
      return updateStoreAPIKeyhandler(APIKEY);
    },
  });
  const updateStorehandler = async (message: string) => {
    try {
      const token = await getAccessTokenSilently();
      const store_id = parseInt(storeId);
      const { data, error } = await updateStore(token, store_id, message);
      if (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { message: error.message, type: 'income' },
        ]);
      } else {
        setStoreSelectionData(data.data);
        if (data.data?.message) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { message: data.data.message, type: 'income' },
          ]);
        }
      }
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };
  const updateStoreAPIKeyhandler = async (APIKEY: string) => {
    try {
      const token = await getAccessTokenSilently();
      const { data, error } = await updateStoreAPIKey(token, APIKEY);
      console.log(APIKEY, data, 'APIKEY');
      if (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { message: error.message, type: 'income' },
        ]);
      } else if (data == 'Saved') {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };
  const handleStoreSelection = (storeId: string | null) => {
    if (storeId) {
      setStoreSelectionData((prevData) => ({
        ...prevData,
        dataType: ['input'],
      }));
      selectStoreMutation(storeId);
    } else {
      console.error('Store ID is null or invalid');
    }
  };
  useEffect(() => {
    if (storeSelectionData?.dataType?.includes('input')) {
      setFocusInput(true);
    }
    if (BotStatus === 'success') {
      if (messages.length === 0) {
        if (BotData.data.data?.message) {
          setStoreSelectionData(BotData.data.data);
          setMessages((prevMessages) => [
            ...prevMessages,
            { message: BotData.data.data.message, type: 'income' },
          ]);
        }
      }
    }
  }, [storeSelectionData, BotStatus, BotData]);
  const handleSendMessage = (message: string) => {
    if (storeSelectionData?.dataType?.includes('input')) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { message, type: 'outcome' },
      ]);
      selectStoreUpdateMutation(message);
    }
    console.log('Sending message:', message);
  };
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  if (BotStatus === 'pending') {
    return (
      <div className="px-20 h-[90vh] w-full grid place-items-center">
        <ChatLoading isText={false} />
      </div>
    );
  }
  if (BotStatus === 'error') {
    return <div className="px-20">Error</div>;
  }
  const actionCreator = ({ type, data }: { type: string; data: any }) => {
    console.log('typ------data', data, type);
    if (type === 'dropdown') {
      return (
        <Select
          style={{ width: 200 }}
          placeholder="Pick a store"
          onChange={(storeId) => {
            if (storeId !== null) {
              const selectedStore = data.find((store: any) => {
                return store.store_id == parseInt(storeId);
              });
              if (selectedStore) {
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    message: selectedStore.store_name,
                    type: 'outcome',
                  },
                ]);
                setStoreId(storeId);
                handleStoreSelection(storeId);
              }
            }
          }}
          data={data?.map((store: any) => ({
            value: store.store_id.toString(),
            label: store.store_name,
          }))}
        />
      );
    }
    if (type === 'button') {
      return (
        <Button
          style={{ width: 200 }}
          variant="outline"
          color="black"
          size="sm"
          className="get-started-btn"
          onClick={() => window.open(data, '_blank')}
        >
          Get Started
        </Button>
      );
    }
    if (type === 'instruction') {
      return (
        <>
          <Button
            style={{ width: 200 }}
            variant="outline"
            color="black"
            size="sm"
            className="get-started-btn"
            onClick={() => setModalOpen(true)}
          >
            Get Started
          </Button>
          <Modal
            opened={modalOpen}
            onClose={() => setModalOpen(false)}
            title={
              <div className="text-xl flex  font-semibold text-center">
                Instructions
              </div>
            }
            size="auto"
            centered
          >
            <div className=" mx-12 flex flex-col justify-center">
              <StepperComponent
                steps={data}
                callBack={(value) => updateApiKey(value)}
              />
            </div>
          </Modal>
        </>
      );
    }
    if (type === 'success') {
      setTimeout(() => {
        navigate('/store-info');
      }, 5000);
    }
    return null;
  };
  console.log(messages);
  return (
    <div className="px-[5%]">
      <div className="relative fade-out">
        <div className="flex flex-col justify-between h-[92vh] ">
          <div
            className={`flex flex-col ${
              storeSelectionData?.dataType &&
              storeSelectionData.dataType.includes('instruction')
                ? 'justify-center mt-20'
                : 'justify-end mb-12'
            } pb-4 pt-3 flex-grow gap-5`}
          >
            <div>
              <Flex direction="column" gap="sm">
                {messages.map((msg, index) => {
                  return (
                    <Fragment key={index}>
                      <ChatMessage message={msg.message} type={msg.type} />

                      {(index == messages.length - 1 ||
                        messages.length == 1) && (
                        <div>
                          {storeSelectionData?.dataType &&
                            storeSelectionData?.dataType.map((type) => {
                              const matchedData = storeSelectionData.data.find(
                                (item: any) => {
                                  console.log(
                                    item,
                                    storeSelectionData,
                                    Object.keys(item).includes(type),
                                    Object.keys(item),
                                    type,
                                    'ssss---sss'
                                  );
                                  return Object.keys(item).includes(type);
                                }
                              );

                              if (matchedData) {
                                console.log(
                                  matchedData,
                                  storeSelectionData,
                                  storeSelectionData.data[type],
                                  type,
                                  'storeSelectionDatastoreSelectionData'
                                );
                              }

                              if (matchedData) {
                                return (
                                  <div className="px-8 mb-8">
                                    {actionCreator({
                                      type: type,
                                      data: matchedData[type],
                                    })}
                                  </div>
                                );
                              }
                              return null;
                            })}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
                <div ref={messagesEndRef} />
              </Flex>
            </div>
            {(isStorePending || isStoreUpdatePending) && (
              <div className="p-5 mb-8 flex flex-start">
                <ChatLoading />
              </div>
            )}
          </div>
          <div className="flex flex-row relative w-full">
            {storeSelectionData?.dataType?.includes('success') ||
            storeSelectionData?.dataType?.includes('instruction') ? null : (
              <div className="fixed bottom-4 left-[15%] w-full px-8 max-w-[70%] mx-auto">
                <ChatInput
                  onSendMessage={handleSendMessage}
                  animateFocus={focusInput}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Onboarding;
