import { useState, useEffect } from "react";
import { motion, useAnimate } from "framer-motion";

export const AnimatedTShirt = () => {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [scope, animate] = useAnimate();
  const [preloadedImages, setPreloadedImages] = useState<HTMLImageElement[]>(
    []
  );

  useEffect(() => {
    const totalFrames = 74;
    const images: HTMLImageElement[] = [];

    const loadImages = async () => {
      const imagePromises = [];

      for (let i = 0; i < totalFrames; i++) {
        const frameIndex = String(i).padStart(2, "0");
        const imgSrc = require(`src/assets/Landing/t-shirt/MagicTshirtanim000864${frameIndex}.png`);

        const img = new Image();
        img.src = imgSrc;
        imagePromises.push(
          new Promise<void>((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = () =>
              reject(new Error(`Failed to load image ${imgSrc}`));
          })
        );

        images.push(img);
      }

      try {
        await Promise.all(imagePromises);
        setPreloadedImages(images);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    loadImages();

    const fps = 24;
    const frameDuration = 1 / fps;
    const totalDuration = totalFrames * frameDuration;

    const animation = animate(0, totalFrames - 1, {
      duration: totalDuration,
      ease: "linear",
      repeat: Infinity,
      onUpdate: (latest) => {
        setCurrentFrame(Math.floor(latest));
      },
    });

    return () => animation.stop();
  }, [animate]);

  const frameSrc = preloadedImages[currentFrame]?.src;

  return (
    <motion.div
      ref={scope}
      className="absolute right-0 bottom-[5vh] z-40"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {frameSrc && (
        <motion.img
          src={frameSrc}
          alt="Animated T-shirt"
          className="w-auto h-[55vh]"
        />
      )}
    </motion.div>
  );
};
