import { memo, useRef, useState } from "react";
import { Image } from "@mantine/core";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { AnimatedTShirt } from "../animated-t-shirt";
import { AnimatedText } from "../../../components";

import firstImage from "../../../../../assets/Landing/FirstPage-1.jpg";
import secondImage from "../../../../../assets/Landing/FirstPage-2.jpg";
import thirdImage from "../../../../../assets/Landing/FirstPage-4.jpg";
import fourthImage from "../../../../../assets/Landing/FirstPage-3.png";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const InitialSlide = memo(() => {
  const [isTShirtAnimated, setIsTShirtAnimated] = useState(false);

  const container = useRef<HTMLDivElement | null>(null);
  const scrollTimeout = useRef<number | null>(null);
  const tl = useRef<GSAPTimeline | null>(null);

  useGSAP(
    () => {
      gsap.to(container.current, {
        scrollTrigger: {
          trigger: container.current,
          start: "-50% top",
          end: () => "+=" + window.innerHeight * 2,
          scrub: 1,
          onUpdate: () => {
            setIsTShirtAnimated(true);

            if (scrollTimeout.current) {
              clearTimeout(scrollTimeout.current);
            }

            scrollTimeout.current = setTimeout(() => {
              setIsTShirtAnimated(false);
            }, 500) as unknown as number;
          },
        },
      });

      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: "top 30%",
          end: "90% 120%",
          scrub: 1.5,
        },
      });

      tl.current.fromTo(
        "#title",
        { yPercent: -200, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1.5, // Longer duration for slower animations
          ease: "power3.out",
        }
      );

      tl.current.fromTo(
        "#first",
        { yPercent: -150, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1.5, // Slower transition
          ease: "power3.out",
        },
        "-=1"
      );

      tl.current.fromTo(
        "#second",
        { yPercent: 150, xPercent: -150, opacity: 0 },
        {
          yPercent: 0,
          xPercent: 0,
          opacity: 1,
          duration: 2, // Longer duration for a more drawn-out effect
          ease: "power3.out",
        },
        "-=1"
      );

      tl.current.fromTo(
        "#fifth",
        { yPercent: 150, xPercent: -150, opacity: 0 },
        {
          yPercent: 0,
          xPercent: 0,
          opacity: 1,
          duration: 2,
          ease: "power3.out",
        },
        "-=0.8"
      );

      tl.current.fromTo(
        "#third",
        { yPercent: 100, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          duration: 1.5,
          ease: "power3.out",
        },
        "-=1.5"
      );

      tl.current.fromTo(
        "#fourth",
        { yPercent: 200, xPercent: 150, opacity: 0 },
        {
          yPercent: 0,
          xPercent: 0,
          opacity: 1,
          duration: 2, // Longer duration for a slower animation
          ease: "power3.out",
        },
        "-=1.5"
      );
    },
    { scope: container }
  );

  return (
    <div
      ref={container}
      className="slide flex w-screen h-[calc(100vh - 65px)] px-5 md:px-[70px] pt-5 md:pt-10 pb-[100px] gap-7"
    >
      <div className="flex flex-col gap-7">
        <div id="title" className="flex-grow flex items-center">
          <AnimatedText
            fw={500}
            textContent="M-AGI-C transforms your dropshipping business with a complete, automated, and optimized solution. Add perfectly crafted products to your store and let artificial intelligence accelerate your growth."
            textSize={{ base: 22, md: 26, lg: 32 }}
          />
        </div>

        <div className="flex flex-col sm:flex-row items-center gap-7 flex-grow overflow-hidden">
          <div className="sm:w-3/5 flex-none h-max sm:h-full">
            <Image
              id="third"
              src={thirdImage}
              className="sm:h-full"
              style={{
                objectFit: "contain",
                backgroundPosition: "center",
                border: "2px solid grey",
              }}
            />
          </div>

          <div id="second" className="h-max sm:h-full hidden sm:block">
            <Image
              src={firstImage}
              h="100%"
              style={{
                objectFit: "cover",
                backgroundPosition: "center",
                border: "2px solid grey",
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex-col gap-7 hidden lg:flex w-1/3 flex-none">
        <Image
          id="first"
          src={fourthImage}
          style={{
            objectFit: "contain",
            backgroundPosition: "center",
            border: "2px solid grey",
          }}
        />

        <Image
          id="fourth"
          src={secondImage}
          h="50%"
          style={{
            objectFit: "cover",
            backgroundPosition: "center",
            border: "2px solid grey",
            flex: "1 0 50%",
          }}
        />

        <div id="fifth" className="absolute right-[7vw] bottom-[10vh] z-40">
          <AnimatedTShirt />
        </div>
      </div>
    </div>
  );
});
