import { FC, useEffect, useState, useRef, ReactNode } from 'react';
import { SQSPersonaMessage } from 'src/models/persona';
import { SQSProductDraftMessage, SQSProductMessage } from 'src/models/product';
import { AnimatedText } from 'src/modules/landing-screens';
import personadefaultimage from 'src/assets/personas/01.png';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { ChatLoading } from 'src/components/ChatLoading';
interface CreationProcessProps {
  header?: ReactNode;
  currentProductCount?: number;
  websoketMessages?: SQSProductDraftMessage[] | SQSProductDraftMessage[];
  creationMessages: SQSPersonaMessage[] | SQSProductMessage[];
  stepsQuantity: number;
}

export const CreationProcess: FC<CreationProcessProps> = ({
  header,
  currentProductCount,
  creationMessages,
  websoketMessages,
  stepsQuantity,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [modelItem, setModelItem] = useState<SQSProductDraftMessage | null>();
  const [displayProgress, setDisplayProgress] = useState(0);
  const [currentMessage, setCurrentMessage] = useState<
    SQSPersonaMessage | SQSProductMessage | null
  >(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const previousProgress = useRef(0);
  const groupTasksByProduct = (messages: SQSProductDraftMessage[] = []) => {
    return messages.reduce((acc, message) => {
      const count = message.count ?? 0;
      if (!acc[count]) {
        acc[count] = [];
      }
      acc[count].push(message);
      return acc;
    }, {} as Record<number, SQSProductDraftMessage[]>);
  };
  const resetState = () => {
    setDisplayProgress(0);
    setCurrentMessage(null);
    setIsAnimating(false);
    previousProgress.current = 0;
  };

  useEffect(() => {
    resetState();
  }, [currentProductCount]);

  const isPersonaMessage = (
    message: SQSPersonaMessage | SQSProductMessage | null
  ): message is SQSPersonaMessage => {
    return message !== null && 'description' in message;
  };

  useEffect(() => {
    if (creationMessages.length === 0) return;
    const newMessage = creationMessages.at(-1);
    newMessage && setCurrentMessage(newMessage);
  }, [creationMessages.length]);

  useEffect(() => {
    if (creationMessages.length === 0) return;

    const currentStep = websoketMessages
      ? websoketMessages?.length
      : creationMessages.length - 1;
    const targetProgress = Math.round(
      (currentStep / (stepsQuantity - 1)) * 100
    );
    let startTime: number;
    setIsAnimating(true);

    const animate = (currentTime: number) => {
      if (!startTime) startTime = currentTime;
      const elapsed = currentTime - startTime;
      const duration = 4000;

      if (elapsed < duration) {
        const progress = elapsed / duration;
        // const eased = 1 - Math.pow(1 - progress, 3);
        const progressValue = Math.round(
          previousProgress.current +
            (targetProgress - previousProgress.current) * progress
        );
        setDisplayProgress(progressValue);
        requestAnimationFrame(animate);
      } else {
        setDisplayProgress(targetProgress);
        previousProgress.current = targetProgress;
        setIsAnimating(false);
      }
    };

    requestAnimationFrame(animate);
  }, [creationMessages.length]);
  const ImageExtracter = (item: SQSProductDraftMessage) => {
    if (item?.prompt?.image) return item.prompt.image;
    if (item?.final_result?.image) return item.final_result.image;
    return personadefaultimage;
  };
  const prompt = [
    {
      prompt: {
        persona_id: 10,
        blueprint_id: 61,
        prompt: 'prompt detail....',
        price: 20,
      },
      message: 'Your prompt has been successfully generated!',
      status: 'success',
      artwork_task_id:
        'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
      count: 0,
    },
    {
      prompt: {
        persona_id: 10,
        blueprint_id: 61,
        prompt: 'prompt detail....',
        price: 20,
        image: 'https://fal.media/files/monkey/zK_ttULeZfT-Jnp84mt7b.png',
        artwork_task_id:
          'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
        count: 0,
      },
      message: 'Your Image has been generated!',
      status: 'success',
      artwork_task_id:
        'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
      count: 0,
    },
    {
      final_result: {
        persona_id: 10,
        blueprint_id: 61,
        prompt: 'prompt detail....',
        price: 20,
        image: 'https://fal.media/files/monkey/zK_ttULeZfT-Jnp84mt7b.png',
        artwork_task_id:
          'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
        count: 0,
        final_image: 'https://fal.media/files/monkey/zK_ttULeZfT-Jnp84mt7b.png',
      },
      message:
        'Your final image has been generated and is now ready for you to view.',
      status: 'success',
      artwork_task_id:
        'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
      count: 0,
    },
    {
      prompt: {
        persona_id: 10,
        blueprint_id: 61,
        prompt: 'prompt detail....',
        price: 20,
      },
      message: 'Your prompt has been successfully generated!',
      status: 'success',
      artwork_task_id:
        'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
      count: 1,
    },
    {
      prompt: {
        persona_id: 10,
        blueprint_id: 61,
        prompt: 'prompt detail....',
        price: 20,
        image: 'https://fal.media/files/monkey/zK_ttULeZfT-Jnp84mt7b.png',
        artwork_task_id:
          'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
        count: 1,
      },
      message: 'Your Image has been generated!',
      status: 'success',
      artwork_task_id:
        'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
      count: 1,
    },
    {
      final_result: {
        persona_id: 10,
        blueprint_id: 61,
        prompt: 'prompt detail....',
        price: 20,
        image: 'https://fal.media/files/monkey/zK_ttULeZfT-Jnp84mt7b.png',
        artwork_task_id:
          'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
        count: 0,
        final_image: 'https://fal.media/files/monkey/zK_ttULeZfT-Jnp84mt7b.png',
      },
      message:
        'Your final image has been generated and is now ready for you to view.',
      status: 'success',
      artwork_task_id:
        'magic-20250125072347-2604c114-3653-4e16-9fce-60ae27fed747',
      count: 1,
    },
  ];
  const groupedTasks = groupTasksByProduct(websoketMessages);

  // Calculate total progress
  const totalSteps = currentProductCount && currentProductCount * 3;
  const completedSteps = Object.values(groupedTasks).reduce(
    (sum, tasks) =>
      sum +
      tasks.filter(
        (t) => t.status === 'success' && (t.final_result || t.prompt?.image)
      ).length,
    0
  );

  // Update displayProgress calculation
  useEffect(() => {
    if (currentProductCount === 0) return;

    const targetProgress = totalSteps
      ? Math.round((completedSteps / totalSteps) * 100)
      : 0;
    // ... rest of the existing animation logic ...
  }, [completedSteps, totalSteps]);

  // Render loading skeletons
  const renderLoadingSkeletons = () => {
    return Array.from({ length: currentProductCount || 4 }).map((_, index) => (
      <div
        key={`loading-${index}`}
        className="relative bg-gray-300 grid place-items-center text-center rounded-lg overflow-hidden shadow-lg h-[40vh] w-full"
      >
        <div className="absolute inset-0 flex flex-col justify-center items-center p-4 text-white">
          <p className="text-lg font-semibold mb-2">
            Loading
            <ChatLoading />
          </p>
        </div>
      </div>
    ));
  };
  const ProductCard = ({
    tasks,
    onViewDetails,
  }: {
    tasks: SQSProductDraftMessage[];
    onViewDetails: (task: SQSProductDraftMessage) => void;
  }) => {
    const completedSteps = tasks.filter(
      (t) => t.status === 'success' && (t.final_result || t.prompt?.image)
    ).length;

    const totalSteps = 3; // Always 3 steps per product
    const progress = (completedSteps / totalSteps) * 100;

    // Get latest message
    const latestMessage = tasks[tasks.length - 1]?.message || 'Processing...';

    return (
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${ImageExtracter(
            tasks[tasks.length - 1]
          )})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        className="relative bg-gray-300 grid place-items-center text-center rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 h-[40vh] w-full"
      >
        <div className="h-full flex flex-col justify-between">
          {/* Progress Bar */}
          <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
            <div
              className="bg-blue-600 rounded-full h-2 transition-all duration-500"
              style={{ width: `${progress + 35}%` }}
            />
          </div>

          {/* Content */}
          <div className="flex-1 text-white flex flex-col items-center justify-center">
            <p className="text-lg font-semibold mb-2 text-center">
              {latestMessage}
              {progress < 60 && <ChatLoading isWhite />}
            </p>

            <button
              onClick={() => onViewDetails(tasks[tasks.length - 1])}
              className="px-4 py-2 bg-white bg-opacity-20 cursor-pointer backdrop-blur-sm rounded-md text-white font-medium hover:bg-opacity-30 transition-all duration-300 border border-white border-opacity-20 hover:border-opacity-40"
            >
              View Detail
            </button>
          </div>

          {/* Step Indicators */}
          <div className="flex justify-center space-x-2 mt-4">
            {Array.from({ length: totalSteps }).map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index < completedSteps ? 'bg-blue-600' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (!currentMessage) return null;

  return (
    <div className="h-full w-full flex flex-col gap-5">
      {header && header}
      <Modal
        opened={opened}
        onClose={close}
        title="Artwork Details"
        size="lg"
        centered
      >
        {modelItem && (
          <div className="space-y-4">
            {/* Image Section */}
            <div
              style={{
                backgroundImage: `url(${ImageExtracter(modelItem)})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              className="h-64 rounded-lg flex items-end p-4"
            >
              <div className="bg-black bg-opacity-50 p-3 rounded-lg w-full">
                <h2 className="text-white text-xl font-semibold">
                  {modelItem.message}
                </h2>
              </div>
            </div>

            {/* Details Section */}
            <div className="space-y-2">
              <h3 className="text-lg font-bold text-gray-800">
                Prompt Details
              </h3>
              <p className="text-gray-600">
                {modelItem.prompt?.prompt || modelItem.final_result?.prompt}
              </p>
            </div>

            {/* Metadata Section */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-semibold text-gray-700">
                  Persona ID
                </h4>
                <p className="text-gray-600">
                  {modelItem.prompt?.persona_id ||
                    modelItem.final_result?.persona_id}
                </p>
              </div>
              <div>
                <h4 className="text-sm font-semibold text-gray-700">
                  Blueprint ID
                </h4>
                <p className="text-gray-600">
                  {modelItem.prompt?.blueprint_id ||
                    modelItem.final_result?.blueprint_id}
                </p>
              </div>
              <div>
                <h4 className="text-sm font-semibold text-gray-700">
                  Profit Margin
                </h4>
                <p className="text-gray-600">
                  {modelItem.prompt?.price || modelItem.final_result?.price}%
                </p>
              </div>
              <div>
                <h4 className="text-sm font-semibold text-gray-700">Status</h4>
                <p className="text-gray-600 capitalize">{modelItem.status}</p>
              </div>
            </div>

            {/* Final Image Section (if available) */}
            {modelItem.final_result?.final_image && (
              <div className="space-y-2">
                <h3 className="text-lg font-bold text-gray-800">Final Image</h3>
                <img
                  src={modelItem.final_result.final_image}
                  alt="Final Artwork"
                  className="rounded-lg w-full"
                />
              </div>
            )}
          </div>
        )}
      </Modal>
      <div className="relative overflow-x-auto h-full w-full">
        {/* <div className="flex-grow absolute grid grid-cols-2 gap-6  h-full w-full overflow-x-auto">
          {Array.from({
            length: Math.max(
              websoketMessages && websoketMessages.length < 0
                ? websoketMessages?.length
                : 4,
              0
            ),
          })?.map((item, index) => (
            <div className="relative bg-gray-300 grid place-items-center text-center rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 h-[40vh] w-full">
              <div className="absolute inset-0 flex flex-col justify-center items-center p-4 text-white">
                <p className="text-lg font-semibold mb-2">
                  Loading
                  <ChatLoading />
                </p>
              </div>
            </div>
          ))}
        </div> */}

        {/* <div className="flex-grow absolute grid grid-cols-2 gap-6  h-full w-full overflow-x-auto">
          {websoketMessages?.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(${ImageExtracter(
                  item
                )})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              className="relative bg-gray-300 grid place-items-center text-center rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 h-[40vh] w-full"
            >
              <div className="absolute inset-0 flex flex-col justify-center items-center p-4 text-white">
                <p className="text-lg font-semibold mb-2">{item?.message}</p>
                <button
                  onClick={() => {
                    setModelItem(item);
                    open();
                  }}
                  className="px-4 py-2 bg-white bg-opacity-20 cursor-pointer backdrop-blur-sm rounded-md text-white font-medium hover:bg-opacity-30 transition-all duration-300 border border-white border-opacity-20 hover:border-opacity-40"
                >
                  Detail
                </button>
              </div>
            </div>
          ))}
        </div> */}
        {/* Main Progress Bar */}
        {/* <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm font-medium">Overall Progress</span>
            <span className="text-sm text-gray-600">{displayProgress}%</span>
          </div>
          <div className="relative w-full h-3">
            <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200" />
            <div
              className={`absolute top-1/2 left-0 h-0.5 bg-blue-600 ${
                isAnimating
                  ? 'transition-none'
                  : 'transition-all duration-300 ease-out'
              }`}
              style={{
                width: `${displayProgress}%`,
                transform: 'translateZ(0)',
              }}
            />
          </div>
        </div> */}

        {/* Product Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 flex-1 overflow-y-auto">
          {websoketMessages?.length === 0
            ? renderLoadingSkeletons()
            : Object.entries(groupedTasks).map(([count, tasks]) => (
                <ProductCard
                  key={`product-${count}`}
                  tasks={tasks}
                  onViewDetails={(task) => {
                    setModelItem(task);
                    open();
                  }}
                />
              ))}
        </div>
      </div>

      <div className="flex justify-between gap-3">
        <div className="max-h-[25vh] overflow-auto">
          <AnimatedText
            textContent={
              isPersonaMessage(currentMessage)
                ? currentMessage.description
                : currentMessage.message
            }
            speed={2}
            className="italic"
            fromColor="gray"
            toColor="gray"
            textSize={16}
          />
        </div>
        <span className="text-gray-400 text-xs">{displayProgress}%</span>
      </div>

      <div className="flex flex-col gap-4">
        <AnimatedText textContent={currentMessage?.title} speed={2} />

        <div className="relative w-full h-3">
          <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200" />

          <div
            className={`absolute top-1/2 left-0 h-0.5 bg-black ${
              isAnimating
                ? 'transition-none'
                : 'transition-all duration-300 ease-out'
            }`}
            style={{
              width: `${displayProgress}%`,
              transform: 'translateZ(0)',
            }}
          />

          <div className="absolute top-1/2 left-0 right-0 flex justify-between">
            {Array.from({ length: stepsQuantity }).map((_, index) => {
              const dotPosition = (index / (stepsQuantity - 1)) * 100;
              const isFilled = displayProgress >= dotPosition;
              const isActive = websoketMessages
                ? websoketMessages.length === index
                : creationMessages.length - 1 === index;

              return (
                <div
                  key={index}
                  className="relative w-3 h-3 transform -translate-y-1/2"
                >
                  <div
                    className={`absolute top-0 left-0 w-full h-full rounded-full transition-colors duration-300 
                                        ${isFilled ? 'bg-black' : 'bg-gray-200'}
                                        ${isActive ? 'animate-growPulse' : ''}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
