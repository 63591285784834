import { PlanSlider } from "src/modules/plans";
import { ApiKeyInfo, SettingsButton } from "src/modules/settings";
import { useUserContext } from "src/contexts";

export const CreditsSettings = () => {
  const { userData, profileInfoOpened, setProfileInfoOpened } =
    useUserContext();

  const openProfileInfo = () => {
    setProfileInfoOpened(true);
  };

  return (
    <div className="h-[60%] flex-none flex flex-col gap-5 lg:gap-8 justify-between text-sm md:text-lg p-10 xl:px-16 overflow-y-auto overflow-x-hidden font-medium select-none transition-all duration-300">
      <div className="flex justify-between items-center">
        <h3 className="text-xl md:text-2xl font-medium leading-none m-0">
          Current Credits
        </h3>

        {!profileInfoOpened && (
          <SettingsButton onClick={openProfileInfo}>
            View profile
          </SettingsButton>
        )}
      </div>

      <PlanSlider
        labelLeft={String(userData?.credit_balance)}
        value={userData?.credit_balance}
        max={userData?.credit_balance * 1.75}
      />

      <PlanSlider
        labelLeft="Credits used this month"
        labelRight="356 credits"
        value={356}
        max={500}
      />

      <p className="text-zinc-400 font-normal m-0">
        At your current usage rate, credits will last at
        <span className="text-black"> 01.02.2025</span>
      </p>

      <ApiKeyInfo />

      <p className="text-zinc-400 font-normal m-0">Supplier</p>
    </div>
  );
};
