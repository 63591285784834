import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useDisclosure } from '@mantine/hooks';
import { Flex, Loader } from '@mantine/core';
import { PageLayout } from 'src/components/page-layout';
import {
  createPersona,
  getPersonasData,
  savePersonas,
  filterPersonas,
} from 'src/services/automation.service';
import {
  CreatedPersonaType,
  PersonaDataType,
  SQSPersonaMessage,
} from 'src/models/persona';
import { PersonasFilterPills } from 'src/modules/filter/pills/personas-pills';
import { useFilterContext } from 'src/contexts';
import {
  CreatedPersonas,
  PersonasController,
  PersonaInfo,
  MyPersonaForm,
  StepElem,
} from 'src/modules/personas-screens/components';
import { WelcomeMessage } from 'src/modules/products-screens/products-chat/components/welcome-message';
import { useWebsocketClient } from 'src/modules/websocket-client';
import { Filter } from 'src/modules/filter';
import { HorizontalCardsGrid } from 'src/components/grids/horizontal-cards-grid';
import { useStoreContext } from 'src/contexts';
import { usePersonas } from 'src/selected-personas-provider';
import { PersonaElements } from 'src/modules/personas-screens/components/persona-elements';
import { CreationProcess } from 'src/modules/creation-process';
import { NoStoresModal } from 'src/modules/modal-manager/components/no-stores-modal';
import { ChatLoading } from 'src/components/ChatLoading';

export const MyPersonas = () => {
  const {
    personasData,
    setPersonasData,
    createdPersonas,
    setCreatedPersonas,
    selectedPersonaData,
  } = usePersonas();

  const { messages: websoketMessages } = useWebsocketClient();
  const [creationMessages, setCreationMessages] = useState<SQSPersonaMessage[]>(
    []
  );
  const { stores, storeId, isLoading: isStoreLoading } = useStoreContext();

  const [isCreateFormOpened, setIsCreateFormOpened] = useState(false);
  const [filterOpened, { open: openFilter, close: closeFilter }] =
    useDisclosure(false);
  const [infoModalOpened, { open: openInfoModal, close: closeInfoModal }] =
    useDisclosure();
  const [isCreatedPersonasOpened, setIsCreatedPersonasOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const successfullTitle = 'Persona Creation Process Completed';
  const creationStepsQuantity = 5;

  useEffect(() => {
    const taskId = creationMessages[0]?.task_id;

    const newMessage: SQSPersonaMessage | null =
      websoketMessages.at(-1)?.task_id == taskId
        ? websoketMessages.at(-1)
        : null;

    if (!newMessage) return;

    const isAlreadySaved = newMessage.title === creationMessages.at(-1)?.title;
    const isProcessCompleted =
      newMessage.title === successfullTitle &&
      creationMessages.length + 1 === creationStepsQuantity;

    !isAlreadySaved && setCreationMessages([...creationMessages, newMessage]);
    isProcessCompleted &&
      setCreatedPersonas(newMessage?.result as CreatedPersonaType[]);
  }, [websoketMessages.length]);

  const { state: filters } = useFilterContext();

  const {
    data: personasQuery,
    isLoading: isPersonasLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['personas', filters.personas],
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      // Apply filters if they exist and have values
      if (
        filters.personas &&
        Object.values(filters.personas).some((value) => value)
      ) {
        const params = new URLSearchParams();
        Object.entries(filters.personas).forEach(([key, value]) => {
          if (!value) return;

          if (
            key === 'store' &&
            typeof value === 'object' &&
            'store_id' in value
          ) {
            if (value.store_id) {
              params.append('store_id', value.store_id.toString());
            }
          } else if (typeof value === 'string' || typeof value === 'number') {
            params.append(key, value.toString());
          }
        });

        if (params.toString()) {
          return filterPersonas(token, params.toString());
        }
      }

      return getPersonasData(token);
    },
  });

  const personas: PersonaDataType[] = useMemo(() => {
    return isSuccess ? personasQuery?.data : [];
  }, [personasQuery?.data, isSuccess]);

  useEffect(() => {
    setPersonasData(personas);
  }, [personas]);

  const handleManualCreation = () => {
    setIsCreateFormOpened(!isCreateFormOpened);
  };

  const handleAutoCreation = async () => {
    try {
      setIsLoading(true);

      const token = await getAccessTokenSilently();
      const personaStoreId = storeId || stores.at(0)?.store_id;

      if (!personaStoreId) return;

      const { persona_task_id, message } = (
        await createPersona(token, personaStoreId, '2', '')
      ).data;

      const startMessage: SQSPersonaMessage = {
        task_id: persona_task_id,
        store_id: personaStoreId,
        description: message,
        result: '',
        title: 'Creation ...',
      };

      setCreationMessages([startMessage]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setCreatedPersonas([]);
    setCreationMessages([]);
    setIsCreateFormOpened(false);
    setIsLoading(false);
  };

  const handlePersonasSaving = async (savedNames: string[]) => {
    const token = await getAccessTokenSilently();
    const storeId = creationMessages[0].store_id;

    if (!storeId) return;

    setIsCreatedPersonasOpened(false);
    setIsLoading(true);

    const personas = createdPersonas.map((persona) => {
      return {
        ...persona,
        is_draft: savedNames.includes(persona.persona_name),
      };
    });

    try {
      await savePersonas(token, storeId, personas, savedNames);
    } catch (error) {
      console.log(error);
    }

    resetState();
  };

  if (isLoading || isPersonasLoading || isStoreLoading) {
    return (
      <PageLayout>
        <Flex justify="center" align="center" h="100%">
          <ChatLoading />
        </Flex>
      </PageLayout>
    );
  }

  if (stores.length === 0) {
    return <NoStoresModal />;
  }

  if (isCreatedPersonasOpened) {
    return <CreatedPersonas handlePersonasSaving={handlePersonasSaving} />;
  }

  return (
    <PageLayout>
      {selectedPersonaData && (
        <PersonaInfo opened={infoModalOpened} close={closeInfoModal} />
      )}

      <div className="flex h-full w-full">
        <div
          className={`flex-[0 0 ${
            creationMessages.length > 0 ? 'w-[60%] 60%' : 'w-full 100%'
          }] flex flex-col h-full justify-between overflow-hidden p-5 md:px-8`}
        >
          <div className="flex flex-row gap-5 justify-between items-center w-full">
            <Filter<PersonaDataType>
              opened={filterOpened}
              open={openFilter}
              close={closeFilter}
              type="personas"
              setFilteredData={setPersonasData}
            />
            <div className="flex flex-wrap gap-2">
              <PersonasFilterPills setFilteredData={setPersonasData} />
            </div>
          </div>

          <div className="flex gap-5 flex-row h-full overflow-hidden">
            <div
              className={`flex-[60%] overflow-hidden h-full ${
                personasData?.length === 0 && 'flex items-center justify-center'
              }`}
            >
              <HorizontalCardsGrid
                footer={
                  <PersonasController
                    isCreationProcessStarted={creationMessages.length > 0}
                    handleManualCreation={handleManualCreation}
                    handleAutoCreation={handleAutoCreation}
                  />
                }
              >
                {isCreateFormOpened && !creationMessages.length && (
                  <MyPersonaForm setCreationMessages={setCreationMessages} />
                )}

                {creationMessages.length > 0 && (
                  <StepElem
                    stepTitle={creationMessages.at(-1)?.title || ''}
                    subTitle="Creation of Personas"
                    isCompleted={createdPersonas.length > 0}
                    setIsCreatedItemsOpened={setIsCreatedPersonasOpened}
                  />
                )}

                {personasData?.length === 0 ? (
                  <div className="h-full items-center hidden lg:flex">
                    <WelcomeMessage
                      userName={user?.nickname || 'User'}
                      message="It looks like you haven't created any persona yet. Start by creating your first persona to see it displayed here."
                    />
                  </div>
                ) : (
                  <PersonaElements openInfoModal={openInfoModal} />
                )}
              </HorizontalCardsGrid>
            </div>
          </div>
        </div>

        <div
          className={`${
            creationMessages.length > 0 ? 'flex w-[40%]' : 'hidden w-[0%]'
          } h-full px-12 py-7 overflow-hidden transition-all duration-200 ease-linear`}
          style={{ borderLeft: '1px solid black' }}
        >
          <CreationProcess
            creationMessages={creationMessages}
            stepsQuantity={creationStepsQuantity}
          />
        </div>
      </div>
    </PageLayout>
  );
};
