import { Loader } from "@mantine/core";
import { PageLayout } from "src/components/page-layout";
import {
  CreditsSettings,
  ProfileSidebar,
  TransactionHistory,
} from "src/modules/settings";
import { useUserContext } from "src/contexts";

export const SettingsPage = () => {
  const { isLoading, profileInfoOpened } = useUserContext();

  if (isLoading) {
    return (
      <PageLayout>
        <div className="h-full w-full flex justify-center items-center">
          <Loader />
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div
        className={`h-full flex flex-col transition-all duration-200 ${
          profileInfoOpened ? "w-[63vw]" : "w-full"
        }`}
      >
        <CreditsSettings />
        <TransactionHistory />
      </div>

      <ProfileSidebar />
    </PageLayout>
  );
};
