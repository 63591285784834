import { FC, PropsWithChildren } from "react";

interface PlanSectionProps {
  withBorder?: boolean;
  className?: string;
}

export const PlanSection: FC<PlanSectionProps & PropsWithChildren> = ({
  withBorder = true,
  className,
  children,
}) => {
  return (
    <div
      className={`flex flex-col gap-5 md:gap-10 py-10 px-16 ${className}`}
      style={{ borderBottom: withBorder ? "1px solid black" : "" }}
    >
      {children}
    </div>
  );
};
