import { ChangeEvent, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IconPencil } from "@tabler/icons-react";
import { updateUserProfile } from "src/services/automation.service";
import { useUserContext } from "src/contexts";

export const ProfileImage = () => {
  const { userData } = useUserContext();

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const imageUrl = profileImage
    ? URL.createObjectURL(profileImage)
    : userData.profile_image;

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setIsLoading(false);
  }, [imageUrl]);

  const { getAccessTokenSilently } = useAuth0();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];
    newFile && setProfileImage(newFile);

    const token = await getAccessTokenSilently();
    await updateUserProfile(token, { profile_image: newFile });
  };

  if (isLoading) {
    return (
      <div className="relative h-36 w-36 rounded-md group">
        <div className="h-full w-full bg-gray-300 animate-pulse" />
      </div>
    );
  }

  return (
    <div className="relative h-36 w-36 rounded-md group">
      <img className="h-full w-full" src={imageUrl} draggable={false} />

      <div className="absolute bottom-1 right-1 flex justify-center items-center bg-slate-200 rounded-full p-1 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <IconPencil size={25} />
      </div>

      <input
        onChange={handleFileChange}
        type="file"
        accept="image/jpeg, image/png"
        className="absolute inset-0 opacity-0 cursor-pointer"
      />
    </div>
  );
};
