import { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";

export const usePlansContentWidth = (isInfoModalOpened: boolean): string => {
  const [contentWidth, setContentWidth] = useState("");

  const isTablet = useMediaQuery("(min-width: 768px)");
  const isDesktop = useMediaQuery("(min-width: 1100px)");

  const sizeMap = {
    tablet: "55vw",
    desktop: "65vw",
    default: "100%",
  };

  const getWidth = () => {
    if (!isInfoModalOpened) return sizeMap.default;

    if (isDesktop) return sizeMap.desktop;
    if (isTablet) return sizeMap.tablet;

    return sizeMap.default;
  };

  useEffect(() => {
    setContentWidth(getWidth());
  }, [isInfoModalOpened, isTablet, isDesktop]);

  return contentWidth;
};
