import { useState } from "react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useUserContext } from "src/contexts";
import { TokenField } from "src/modules/settings";

export const ApiKeyInfo = () => {
  const { userData } = useUserContext();
  const [isApiVisible, setIsApiVisible] = useState(false);

  const handleEyeClick = () => {
    setIsApiVisible(!isApiVisible);
  };

  return (
    <div className="flex gap-1 text-zinc-400 font-normal w-full">
      <div className="w-full">
        {isApiVisible ? (
          <>
            <div className="flex gap-2">
              <span>API KEY</span>
              <div onClick={handleEyeClick} className="cursor-pointer">
                {isApiVisible ? <IconEyeOff /> : <IconEye />}
              </div>
            </div>

            {userData.api_keys.map((apiKey, i) => (
              <div key={i} className="w-full overflow-hidden">
                {Object.entries(apiKey).map(
                  ([platform, { ACCESS_TOKEN, REFRESH_TOKEN }]) => (
                    <div key={platform} className="w-full truncate">
                      <span className="m-0 font-medium">{platform}</span>

                      <TokenField label="Access token" token={ACCESS_TOKEN} />
                      <TokenField label="Refresh token" token={REFRESH_TOKEN} />
                    </div>
                  )
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="flex gap-2">
            <span>API KEY: </span>
            <span className="select-text">////////////////////</span>
            <div onClick={handleEyeClick} className="cursor-pointer">
              {isApiVisible ? <IconEyeOff /> : <IconEye />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
