export class WebSocketClient {
  public webSocketInstance!: WebSocket;
  private messageQueue: any[] = [];
  private isProcessingQueue = false;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private reconnectDelay = 1000;
  private receiveMessageInterval: NodeJS.Timeout | null = null;

  constructor() {
    const wsUrl = process.env.REACT_APP_API_WS_SERVER_URL as string;
    if (!wsUrl) {
      throw new Error('WebSocket URL is not defined');
    }

    this.initializeWebSocket(wsUrl);
  }

  private initializeWebSocket(wsUrl: string): void {
    this.webSocketInstance = new WebSocket(wsUrl);

    this.webSocketInstance.onopen = () => {
      console.log('WebSocket connected');
      this.reconnectAttempts = 0;

      if (this.receiveMessageInterval) {
        clearInterval(this.receiveMessageInterval);
      }

      this.receiveMessageInterval = setInterval(() => {
        this.sendMessage({ action: 'RecieveMessage' });
      }, 3000);

      this.processQueue();
    };

    this.webSocketInstance.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Received message:', data);
        // Handle incoming messages here
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    this.webSocketInstance.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.webSocketInstance.onclose = () => {
      console.log('WebSocket closed');
      // Clear the interval when connection closes
      if (this.receiveMessageInterval) {
        clearInterval(this.receiveMessageInterval);
        this.receiveMessageInterval = null;
      }

      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => {
          this.reconnectAttempts++;
          console.log(
            `Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})`
          );
          this.initializeWebSocket(wsUrl);
        }, this.reconnectDelay * Math.pow(2, this.reconnectAttempts));
      }
    };
  }

  private async processQueue(): Promise<void> {
    if (this.isProcessingQueue || this.messageQueue.length === 0) return;

    this.isProcessingQueue = true;

    while (this.messageQueue.length > 0) {
      const message = this.messageQueue[0];

      if (this.webSocketInstance.readyState === WebSocket.OPEN) {
        try {
          this.webSocketInstance.send(JSON.stringify(message));
          this.messageQueue.shift(); // Remove the processed message
        } catch (error) {
          console.error('Error sending message:', error);
          break;
        }
      } else {
        break;
      }

      // Add a small delay between messages to prevent flooding
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    this.isProcessingQueue = false;
  }

  public sendMessage(message: any): void {
    this.messageQueue.push(message);
    this.processQueue();
  }

  public disconnect(): void {
    if (this.receiveMessageInterval) {
      clearInterval(this.receiveMessageInterval);
      this.receiveMessageInterval = null;
    }
    this.messageQueue = [];
    this.isProcessingQueue = false;
    this.reconnectAttempts = this.maxReconnectAttempts; // Prevent reconnection attempts

    if (this.webSocketInstance) {
      this.webSocketInstance.close();
    }
  }
}
