import { FC, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";

interface MenuTabsProps {
  type?: "desktop" | "mobile";
  onLinkClick?: () => void;
}

export const MenuTabs: FC<MenuTabsProps> = ({
  type = "desktop",
  onLinkClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const menuLinksProperties = useMemo(
    () => [
      {
        links: [
          {
            label: "Home",
            path: "/store-info",
            onClick: () => navigate("/store-info"),
          },
          {
            label: "Persona",
            path: "/my-personas",
            onClick: () => navigate("/my-personas"),
          },
          {
            label: "Product",
            path: "/products",
            onClick: () => navigate("/products"),
          },
          {
            label: "Artwork",
            path: "/artworks",
            onClick: () => navigate("/artworks"),
          },
        ],
      },
      {
        links: [
          {
            label: 'Settings',
            onClick: () => navigate('/settings'),
            key: '/settings',
          },
          {
            label: "Plan",
            path: "/plans",
            onClick: () => navigate("/plans"),
          },
          {
            label: "Logout",
            path: "/logout",
            onClick: handleLogout,
          },
        ],
      },
    ],
    [navigate, handleLogout]
  );

  return (
    <Flex
      flex="1 0 auto"
      w="100%"
      px="20%"
      direction="column"
      justify="space-between"
      gap={20}
      pt={40}
      pb={type === "desktop" ? 40 : 130}
    >
      {menuLinksProperties.map(({ links }, index) => (
        <Flex
          key={index}
          direction="column"
          gap={type === "desktop" ? 5 : 20}
          pt={index === 0 && type === "desktop" ? "5vh" : 0}
        >
          {links.map(({ label, path, onClick }) => (
            <span
              key={label}
              className={`${
                location.pathname === path ? "text-black" : "text-gray-500"
              } hover:text-gray-700 transition-colors duration-200 cursor-pointer ${
                type === "desktop" ? "text-start text-lg" : "text-center text-2xl"
              }`}
              onClick={() => {
                onClick();
                onLinkClick && onLinkClick();
              }}
            >
              {label}
            </span>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};
