import { motion } from "framer-motion";

export const SuccessMessage = () => {
  return (
    <motion.div
      className="flex flex-col justify-center gap-2 text-gray-400 text-2xl"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 1 }}
    >
      <span>Congratulations!</span>

      <motion.span
        initial={{ color: "rgb(156, 163, 175)" }}
        animate={{ color: "rgb(0, 0, 0)" }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        {" "}
        Your payment has been processed successfully.{" "}
      </motion.span>

      <span> Thank you for your purchase!</span>
    </motion.div>
  );
};
