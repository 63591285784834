export const creditTypes = [
  {
    quantity: 100,
    price: 9.99,
    discount: null,
  },
  {
    quantity: 500,
    price: 9.99,
    discount: 20,
  },
  {
    quantity: 1000,
    price: 9.99,
    discount: 35,
  },
];
