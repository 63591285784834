import { motion } from "framer-motion";

export const FailedMessage = () => {
  return (
    <motion.div
      className="flex flex-col justify-center gap-5 md:gap-10 text-gray-400 text-2xl"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 1 }}
    >
      <span className="leading-9">
        We're sorry, but
        <motion.span
          initial={{ color: "rgb(156, 163, 175)" }}
          animate={{ color: "rgb(0, 0, 0)" }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          {" "}your payment could not be processed.{" "}
        </motion.span>
        Please check your payment details and try again. If the issue persists,
        contact your bank or try using a different payment method.
      </span>
    </motion.div>
  );
};
