import { memo, ReactNode, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Drawer, Flex, Text } from '@mantine/core';
import { FilterIcon } from 'src/modules/filter/components/filter-icon';
import { useFilterContext } from 'src/contexts';
import { ApiResponse } from 'src/models/api-response';
import { Search } from 'src/modules/products-screens/components/search';
import { ArtworksFilter, PersonasFilter, ProductsFilter } from 'src/modules/filter/variants';
import { filterArtworks, filterPersonas, filterProducts } from 'src/services/automation.service';
import { FilterType, PersonaFiltersType, ArtworkFiltersType, FilterConfig, ProductFilterType } from 'src/modules/filter/types';
import { FILTER_CONFIGS, TransformFilterConfig } from '../config';
import { useInitialFilters } from 'src/modules/filter/utils/use-initial-filters';

interface FilterProps<T> {
  opened: boolean;
  open: () => void;
  close: () => void;
  type: FilterType;
  setFilteredData: (data: T[]) => void;
}

type FilterDataType = PersonaFiltersType | ArtworkFiltersType | ProductFilterType;

type FilterDispatchMap = {
  personas: { type: "SET_PERSONA_FILTER"; payload: PersonaFiltersType };
  artworks: { type: "SET_ARTWORK_FILTER"; payload: ArtworkFiltersType };
  products: { type: "SET_PRODUCTS_FILTER"; payload: ProductFilterType };
};

const dispatchConfig = {
  personas: { type: "SET_PERSONA_FILTER" as const },
  artworks: { type: "SET_ARTWORK_FILTER" as const },
  products: { type: "SET_PRODUCTS_FILTER" as const },
};

const filterHandlers: Record<FilterType, (token: string, filterQuery: string) => Promise<ApiResponse>> = {
  personas: filterPersonas,
  artworks: filterArtworks,
  products: filterProducts,
};

function FilterComponent<T>({ opened, open, close, type, setFilteredData }: FilterProps<T>) {
  const { state: filters, dispatch } = useFilterContext();
  const { initialPersonaFilters, initialProductFilters, initialArtworkFilters } = useInitialFilters();

  const [newFilters, setNewFilters] = useState<FilterDataType>(filters[type]);
  const [isFiltesrApplied, setIsFiltersApplied] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const hasStoreFilter = (filter: FilterDataType): filter is PersonaFiltersType | ProductFilterType => {
    return 'store' in filter && filter.store !== undefined;
  };

  const getInitialValue = (key: string) => {
    switch (type) {
      case 'personas':
        return (initialPersonaFilters as Record<string, any>)[key];
      case 'products':
        return (initialProductFilters as Record<string, any>)[key];
      case 'artworks':
        return (initialArtworkFilters as Record<string, any>)[key];
      default:
        return undefined;
    }
  };

  const isDefaultValue = (key: string, value: any): boolean => {
    const initialValue = getInitialValue(key);

    // Handle undefined/null
    if (!value && !initialValue) return true;
    if (!value || !initialValue) return false;

    // Handle objects (like persona or store)
    if (typeof value === 'object') {
      if (key === 'persona') {
        return !value.id || value.id === (initialValue?.id || 0);
      }
      if (key === 'store') {
        return !value.store_id || value.store_id === (initialValue?.store_id || 0);
      }
      return Object.keys(value).length === 0;
    }

    // Handle numbers
    if (typeof value === 'number') {
      return value === (initialValue || 0);
    }

    // Handle strings
    if (typeof value === 'string') {
      return !value.trim() || value === (initialValue || '');
    }

    return value === initialValue;
  };

  // Set filter type
  useEffect(() => {
    dispatch({
      type: "SET_FILTER_TYPE",
      payload: type
    });
  }, [type, dispatch]);

  const clearFilters = async () => {
    const clearedFilters = type === 'personas' ? initialPersonaFilters : 
                          type === 'products' ? initialProductFilters : 
                          initialArtworkFilters;
    setNewFilters(clearedFilters);
    
    // Update global filter state
    dispatch({
      type: dispatchConfig[type].type,
      payload: clearedFilters
    });
    
    setIsFiltersApplied(true);
    
    // Fetch data with cleared filters
    const token = await getAccessTokenSilently();
    const filterHandler = filterHandlers[type];
    try {
      const filteredData = (await filterHandler(token, '')).data || [];
      setFilteredData(filteredData as T[]);
    } catch (error) {
      console.error('Error fetching filtered data:', error);
      setFilteredData([] as T[]);
    }
  };

  function hasRequestValue<T>(config: FilterConfig): config is TransformFilterConfig<T> {
    return config && 'requestValue' in config && typeof config.requestValue === 'function';
  }

  const getFilterQuery = (filterType: "new_filters" | "state_filters") => {
    const params = new URLSearchParams();
    const filterData = filterType === "new_filters" ? newFilters : filters[type];

    Object.entries(filterData).forEach(([key, value]) => {
      if (!value) return;

      const config = FILTER_CONFIGS[type].find((elem) => elem.key === key);
      if (!config) return;

      if (key === 'store' && hasStoreFilter(filterData) && filterData.store?.store_id) {
        params.append('store_id', filterData.store.store_id.toString());
      } else if (hasRequestValue<typeof value>(config) && config.requestValue) {
        try {
          const paramValue = config.requestValue(value);
          if (paramValue !== undefined && paramValue !== null) {
            params.append(key, paramValue.toString());
          }
        } catch (error) {
          console.error(`Error transforming value for key ${key}:`, error);
        }
      } else if (typeof value === 'string' || typeof value === 'number') {
        params.append(key, value.toString());
      }
    });

    return params.toString();
  };

  const fetchFilteredData = async (filterType: "new_filters" | "state_filters") => {
    const token = await getAccessTokenSilently();
    const filterQuery = getFilterQuery(filterType);
    const filterHandler = filterHandlers[type];

    try {
      const filteredData = (await filterHandler(token, filterQuery)).data || [];
      setFilteredData(filteredData as T[]);
    } catch (error) {
      console.error('Error fetching filtered data:', error);
      setFilteredData([] as T[]);
    }
  };

  const handleApply = async () => {
    try {
      // Update global filter state
      dispatch({
        type: dispatchConfig[type].type,
        payload: newFilters
      });

      setIsFiltersApplied(true);
      await fetchFilteredData("new_filters");
      close();
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  };

  const handleClose = () => {
    if (!isFiltesrApplied) {
      setNewFilters(filters[type]);
    }
    close();
  };

  // Initial load if filters are already applied
  useEffect(() => {
    const currentFilter = filters[type];
    if (isInitialLoad && Object.keys(currentFilter as Record<string, any>).some(key => !isDefaultValue(key, (currentFilter as Record<string, any>)[key]))) {
      fetchFilteredData("state_filters");
      setIsFiltersApplied(true);
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, filters, type]);

  // Handle filter changes
  useEffect(() => {
    if (!isInitialLoad && isFiltesrApplied) {
      fetchFilteredData("state_filters");
    }
  }, [filters[type]]);

  // Keep drawer state in sync with filters
  useEffect(() => {
    if (!opened) {
      setNewFilters(filters[type]);
      setIsFiltersApplied(false);
    }
  }, [opened, filters[type]]);

  const filterVariants: Record<FilterType, ReactNode> = {
    artworks: <ArtworksFilter newFilters={newFilters} setNewFilters={setNewFilters} />,
    personas: <PersonasFilter newFilters={newFilters} setNewFilters={setNewFilters} />,
    products: <ProductsFilter newFilters={newFilters} setNewFilters={setNewFilters} />
  };

  const FilterVariant = filterVariants[type];

  return (
    <>
      <Flex display="flex" justify="space-between" align="center" gap={10} w="100%" style={{ cursor: "pointer" }}>
        <Box onClick={open} w={30} h={30}>
          <FilterIcon />
        </Box>
        <Search />
      </Flex>

      <Drawer
        padding={30}
        size="xs"
        opened={opened}
        onClose={handleClose}
        title={`${type.charAt(0).toUpperCase() + type.slice(1)} Filters`}
        overlayProps={{ bg: "none" }}
        styles={{
          title: { fontSize: 26, fontWeight: 600 },
          close: { height: 40, width: 40 },
          content: { borderRight: '1px solid black' },
          header: { paddingBottom: 20 },
          body: { minHeight: '85%' }
        }}
      >
        <Flex direction="column" justify="space-between" h="100%" gap={20}>
          <Flex direction="column" gap={15}>
            <Flex justify="end">
              <Text onClick={clearFilters} style={{ cursor: 'pointer', color: 'gray' }}>
                Clear filters
              </Text>
            </Flex>

            {FilterVariant}

          </Flex>
          <Button
            variant="outline"
            w="max-content"
            onClick={handleApply}
            style={{ alignSelf: 'center' }}
          >
            Apply
          </Button>
        </Flex>
      </Drawer>
    </>
  );
}

export const Filter = memo(FilterComponent) as <T>(props: FilterProps<T>) => JSX.Element;
