import { memo, useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useWebsocketClient } from 'src/modules/websocket-client';
import { Box, Flex, Loader } from '@mantine/core';
import { PageLayout } from 'src/components/page-layout';
import { HorizontalCardsGrid } from 'src/components/grids/horizontal-cards-grid';
import { Filter } from 'src/modules/filter';
import { WelcomeMessage } from 'src/modules/products-screens/products-chat/components/welcome-message';
import { ProductsFilterPills } from 'src/modules/filter/pills';
import {
  ProductsController,
  ProductInfo,
} from 'src/modules/products-screens/products-list';
import { StepElem } from 'src/modules/personas-screens/components';
import {
  getProducts,
  AutoCreateProduct,
  filterProducts,
} from 'src/services/automation.service';
import { ProductDataType, SQSProductMessage } from 'src/models/product';
import { useFilterContext } from 'src/contexts';
import {
  CreatedProducts,
  ProductCreatingForm,
} from 'src/modules/product-creation-form';
import { useProducts } from 'src/selected-products-provider';
import { CreationProcess } from 'src/modules/creation-process';
import { ProductElements } from 'src/modules/products-screens/components/product-elements';
import { ChatLoading } from 'src/components/ChatLoading';
import { AnimatedText } from 'src/modules/landing-screens';
interface TaskProgress {
  id: string;
  title: string;
  progress: number;
  stepsCompleted: number;
  totalSteps: number;
  startTime: Date;
  estimatedCompletion?: Date;
}

export const ProductsPage = memo(() => {
  const {
    productsData,
    setProductsData,
    selectedProductData,
    createdProducts,
    setCreatedProducts,
    setSavedProducts,
    createdProductsCount,
    setCreatedProductsCount,
  } = useProducts();

  const { messages: websoketMessages } = useWebsocketClient();
  const [creationMessages, setCreationMessages] = useState<
    Record<number, SQSProductMessage[]>
  >({});
  const [
    productInfoOpened,
    { open: openProductInfo, close: closeProductInfo },
  ] = useDisclosure();
  const [filterOpened, { open: openFilter, close: closeFilter }] =
    useDisclosure(false);
  const [isCreateFormOpened, setIsCreateFormOpened] = useState(false);
  const [isCreatedProductsOpened, setIsCreatedProductsOpened] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();
  const { state: filters } = useFilterContext();

  const creationStepsQuantity = createdProductsCount * 3 + 1;

  const {
    data: productsQuery,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['products', filters.products],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      if (
        filters.products &&
        Object.values(filters.products).some((value) => value)
      ) {
        const params = new URLSearchParams();
        Object.entries(filters.products).forEach(([key, value]) => {
          if (!value) return;
          if (key === 'persona' && typeof value === 'object' && 'id' in value) {
            if (value.id) params.append('persona_id', value.id.toString());
          } else if (
            key === 'store' &&
            typeof value === 'object' &&
            'store_id' in value
          ) {
            if (value.store_id)
              params.append('store_id', value.store_id.toString());
          } else if (typeof value === 'string' || typeof value === 'number') {
            params.append(key, value.toString());
          }
        });
        if (params.toString()) return filterProducts(token, params.toString());
      }
      return getProducts(token);
    },
  });

  const products = useMemo(
    () => (isSuccess ? productsQuery?.data : []),
    [productsQuery?.data, isSuccess]
  );

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  useEffect(() => {
    const messages = Object.values(creationMessages).at(-1);
    const taskId = messages?.at(0)?.artwork_task_id;
    const newMessage =
      websoketMessages.at(-1)?.artwork_task_id === taskId
        ? websoketMessages.at(-1)
        : null;

    if (!newMessage) return;

    const defaultMessage = Object.values(creationMessages)
      .at(0)
      ?.at(0) as SQSProductMessage;
    const currentProductMessages =
      creationMessages[newMessage.count]?.length > 0
        ? creationMessages[newMessage.count]
        : [defaultMessage];

    setCreationMessages({
      ...creationMessages,
      [newMessage.count]: [...currentProductMessages, newMessage],
    });

    if (newMessage.final_result) {
      const newProduct = newMessage.final_result;
      setCreatedProducts((prev) => [...prev, newProduct]);
      setSavedProducts([]);
    }
  }, [websoketMessages.length]);

  const handleManualCreation = () => setIsCreateFormOpened(!isCreateFormOpened);

  const handleAutoCreation = async () => {
    try {
      setCreatedProducts([]);
      setCreationMessages({});
      setSavedProducts([]);
      setCreatedProductsCount(0);

      const token = await getAccessTokenSilently();
      const { artwork_task_id, message, quantity } = (
        await AutoCreateProduct(token)
      ).data;

      setIsCreateFormOpened(true);
      setCreatedProductsCount(quantity);

      const startMessage: SQSProductMessage = {
        result: '',
        artwork_task_id,
        message,
        count: 0,
        title: 'Creation ...',
        status: '',
      };

      setCreationMessages({ 0: [startMessage] });
    } catch (error) {
      console.error(error);
    }
  };
  const displayprogress =
    (websoketMessages.length / creationStepsQuantity) * 100;
  const resetState = () => {
    setIsCreatedProductsOpened(false);
    setIsCreateFormOpened(false);
    setCreatedProducts([]);
    setCreationMessages({});
    setSavedProducts([]);
    setCreatedProductsCount(0);
  };
  // Add this state hook in your component
  const [tasksProgress, setTasksProgress] = useState<TaskProgress[]>([]);
  const [expandedTaskId, setExpandedTaskId] = useState<string | null>(null);

  // Add this effect to calculate progress

  //CHANGE
  useEffect(() => {
    if (!websoketMessages) return;

    // Group messages by task/project
    const groupedTasks = websoketMessages.reduce((acc, message) => {
      const taskId = message.artwork_task_id;
      if (!acc[taskId]) {
        acc[taskId] = {
          id: taskId,
          title: message.message,
          stepsCompleted: 0,
          totalSteps: 3, // Assuming 3 steps per task
          startTime: new Date(), // You should get this from the message
          progress: 0,
        };
      }

      // Update progress based on message status
      if (message.status === 'success') {
        acc[taskId].stepsCompleted++;
        acc[taskId].progress =
          (acc[taskId].stepsCompleted / acc[taskId].totalSteps) * 100;
      }

      return acc;
    }, {} as Record<string, TaskProgress>);

    setTasksProgress(Object.values(groupedTasks));
  }, [websoketMessages]);

  // Create a separate component for the progress bar item
  const TaskProgressItem = ({ task }: { task: TaskProgress }) => (
    <div
      className="cursor-pointer p-4 hover:bg-gray-50 rounded-lg transition-colors"
      onClick={() =>
        setExpandedTaskId(expandedTaskId === task.id ? null : task.id)
      }
    >
      <div className="flex justify-between items-center mb-2">
        <span className="font-medium">{task.title}</span>
        <span className="text-sm text-gray-500 ml-2">
          {Math.round(task.progress / createdProductsCount)}%
        </span>
      </div>
      <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="absolute left-0 top-0 h-full bg-blue-600 transition-all duration-500"
          style={{ width: `${task.progress / createdProductsCount}%` }}
        />
      </div>

      {/* Expanded details */}
      {expandedTaskId === task.id && (
        <div className="mt-4 space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-left">Task ID:</span>
            <span className="text-gray-600 text-right">{task.id}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>Started:</span>
            <span className="text-gray-600">
              {task.startTime.toLocaleTimeString()}
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span>Steps Completed:</span>
            <span className="text-gray-600">
              {task.stepsCompleted}/{creationStepsQuantity - 1}
            </span>
          </div>
        </div>
      )}
    </div>
  );
  if (isLoading) {
    return (
      <PageLayout>
        <Flex justify="center" align="center" h="100%">
          <ChatLoading />
        </Flex>
      </PageLayout>
    );
  }

  if (
    isCreatedProductsOpened &&
    createdProducts.length === createdProductsCount
  ) {
    return <CreatedProducts resetState={resetState} />;
  }

  return (
    <PageLayout>
      {selectedProductData && (
        <ProductInfo opened={productInfoOpened} close={closeProductInfo} />
      )}

      <div className="flex h-full">
        <Flex
          direction="column"
          w={`${isCreateFormOpened ? '60%' : '100%'}`}
          display={{ base: `${isCreateFormOpened ? 'none' : ''}`, sm: 'flex' }}
          style={{ borderRight: '1px solid black' }}
          className="p-5 md:px-8"
        >
          <div className="flex flex-row gap-5 justify-between items-center w-full">
            <Filter<ProductDataType>
              opened={filterOpened}
              open={openFilter}
              close={closeFilter}
              type="products"
              setFilteredData={setProductsData}
            />
            <div className="flex flex-wrap gap-2">
              <ProductsFilterPills setFilteredData={setProductsData} />
            </div>
          </div>

          <Box
            flex="1 0 auto"
            className={`flex-[65%] ${
              productsData?.length === 0
                ? 'flex items-center justify-center'
                : ''
            }`}
          >
            {!isLoading && (
              <HorizontalCardsGrid
                footer={
                  <ProductsController
                    handleManualCreation={handleManualCreation}
                    handleAutoCreation={handleAutoCreation}
                  />
                }
              >
                {Object.values(creationMessages).length > 0 && (
                  <div
                    onClick={() => setIsCreateFormOpened(!isCreateFormOpened)}
                    className="h-full aspect-square  rounded-xl text-gray-400 flex flex-col items-center justify-center p-4 gap-2 text-center"
                  >
                    <StepElem
                      stepTitle={
                        Object.values(creationMessages).at(-1)?.at(-1)?.title ||
                        ''
                      }
                      subTitle={
                        <div className="flex flex-col gap-4">
                          {tasksProgress.map((task) => (
                            <TaskProgressItem key={task.id} task={task} />
                          ))}
                        </div>
                      }
                      isCompleted={
                        websoketMessages.length === creationStepsQuantity - 1
                      }
                      setIsCreatedItemsOpened={setIsCreatedProductsOpened}
                    />
                  </div>
                )}

                {productsData?.length === 0 ? (
                  <div className="h-full flex items-center">
                    <WelcomeMessage
                      userName={user?.nickname || 'User'}
                      message="It looks like you haven't created any products yet. Start by creating your first product to see it displayed here."
                    />
                  </div>
                ) : (
                  <ProductElements openProductInfo={openProductInfo} />
                )}
              </HorizontalCardsGrid>
            )}
          </Box>
        </Flex>

        <Flex
          display={isCreateFormOpened ? 'flex' : 'none'}
          w={{ base: '100%', sm: `${isCreateFormOpened ? '40%' : '0%'}` }}
          className="p-5 md:px-8 overflow-hidden transition-all duration-300 ease-linear"
        >
          <Flex direction="column" w="100%" justify="space-between">
            {Object.values(creationMessages).length > 0 ? (
              <CreationProcess
                header={
                  <div className="flex justify-between">
                    {createdProductsCount > 1 && (
                      <span className="text-zinc-500">
                        {/* {`Creation of the ${
                          Object.values(creationMessages).length
                        }th product out of ${createdProductsCount} products`} */}
                      </span>
                    )}
                    {createdProducts.length > 0 && (
                      <button
                        className="ml-auto inline-block md:hidden text-sm sm:text-base bg-inherit outline-none border-none hover:text-gray-700 cursor-pointer"
                        onClick={() => setIsCreatedProductsOpened(true)}
                      >
                        View created products
                      </button>
                    )}
                  </div>
                }
                websoketMessages={websoketMessages}
                currentProductCount={createdProductsCount}
                creationMessages={Object.values(creationMessages).at(-1) || []}
                stepsQuantity={creationStepsQuantity}
              />
            ) : (
              <ProductCreatingForm
                setCreationMessages={setCreationMessages}
                setIsCreateFormOpened={setIsCreateFormOpened}
              />
            )}
          </Flex>
        </Flex>
      </div>
    </PageLayout>
  );
});
