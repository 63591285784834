import { FC, memo } from 'react';
import { Select } from '@mantine/core';
import { useStoreContext } from 'src/contexts';
import { NoStoresModal } from 'src/modules/modal-manager/components/no-stores-modal';
import { FullPageLoader } from 'src/modules/creation-process';

interface StoreSelectorProps {
  setStoreId?: (storeId: number) => void;
  className?: string;
  setEditMode?: (editMode: boolean) => void;
}

export const StoreSelector: FC<StoreSelectorProps> = memo(
  ({ setStoreId, className, setEditMode }) => {
    const { storeId, setStoreId: setContextStoreId, stores, isLoading } = useStoreContext();

    const selectorData = stores?.map((store) => ({
      value: String(store.store_id),
      label: store.store_name,
    }));

    const handleChange = (value: number) => {
      if (setStoreId) {
        setStoreId(value)
      } else if (setContextStoreId) {
        setContextStoreId(value);
      }
    };

    if (isLoading) return <FullPageLoader />

    if (stores.length === 0) return <NoStoresModal />;

    return className ? (
      <select
        className={className}
        value={String(storeId) || String(stores[0]?.store_id)}
        onChange={(e) => {
          handleChange(Number(e.target.value));
          setEditMode && setEditMode(false);
        }}
      >
        {selectorData.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    ) : (
      <Select
        flex="1 0 auto"
        placeholder="Store"
        defaultValue={String(storeId) || String(stores[0]?.store_id)}
        data={selectorData}
        onChange={(value) => handleChange(Number(value))}
      />
    );
  }
);
