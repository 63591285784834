import { FC, useState } from "react";

interface CreditsOptionProps {
  quantity: number;
  price: number;
  discount: number | null;
  onClick: () => Promise<void>;
}

export const CreditsOption: FC<CreditsOptionProps> = ({
  quantity,
  price,
  discount,
  onClick,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-3 py-5 px-12 border border-solid border-black rounded-2xl aspect-square">
      <span>{`+ ${quantity} Credits`}</span>
      <span className="text-zinc-400">{`${price}$`}</span>
      {discount && (
        <span className="text-zinc-400 font-normal">{`-${discount}% off`}</span>
      )}

      <button
        onClick={handleClick}
        disabled={isLoading}
        className="w-full rounded-xl mt-auto p-3 outline-none border bg-transparent hover:bg-zinc-100 disabled:hover:bg-transparent disabled:cursor-not-allowed cursor-pointer transition-colors duration-300"
      >
        {isLoading ? "Just a Moment..." : "Buy Now"}
      </button>
    </div>
  );
};