import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Text, Box, Loader, RingProgress, MultiSelect, Button, Flex, Card, Group, Switch, SimpleGrid, Image } from '@mantine/core';
import { createProductTemplates, getUserSuppliers } from "../../services/automation.service";
import { useNavigate } from "react-router-dom";

export interface SavedProducts {
  [key: string]: boolean;
}

interface CreateProductTemplatesViewProps {
  selectedArtworks: Array<{
    artwork: string;
    prompt?: string;
    target_audience: any;
    originalimage?: string;
    personaname: string;
  }>;
  savedProducts: SavedProducts;
  onSaveChange: (index: number, saved: boolean) => void;
  storeId: number | null;
}

export const CreateProductTemplatesView: React.FC<CreateProductTemplatesViewProps> = ({ 
  selectedArtworks,
  savedProducts,
  onSaveChange,
  storeId
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTemplates, setLoadingTemplates] = useState<any[]>([]);
  const [loadingText, setLoadingText] = useState<string>("Logging to printful...");
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [supplierOptions, setSupplierOptions] = useState<string[]>([]); // Dynamic supplier list
  
  useEffect(() => {
    let isMounted = true;

    const fetchSuppliers = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const suppliersData = await getUserSuppliers(accessToken);
        if (isMounted) {
          // Extract supplier names and update the options for the MultiSelect
          const supplierNames = suppliersData.data.map((supplier: any) => supplier.name);
          setSupplierOptions(supplierNames);
        }
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    if (storeId) {
      fetchSuppliers();
    }

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, storeId]);

  const getSelectedProducts = () => {
    return selectedArtworks.filter((_, index) => savedProducts[index]);
  };

  const createProductTemplate = async () => {
    if (!storeId) {
      alert("Please select a store first");
      return;
    }

    if (suppliers.length === 0) {
      alert("Please select a supplier");
      return;
    }

    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const selectedProducts = getSelectedProducts();
    
    // Ensure each artwork has the store_id
    const artworksWithStore = selectedProducts.map(artwork => ({
      ...artwork,
      store_id: storeId.toString()
    }));
    
    await createProductTemplates(
      accessToken,
      artworksWithStore,
      suppliers,
      (template) => {
        setLoadingTemplates(loadingTemplates => [...loadingTemplates, template]);
        console.log(loadingTemplates);
        if (template.success === false) {
          setLoadingText(`Error creating product template ${JSON.stringify(template.artwork)}: ${template.error}`);
        }
        if (loadingTemplates.length / selectedProducts.length === 1) {
          navigate("/products-dashboard");
        } else {
          setLoadingText(`Product template ${template.id} created!`);
        }
      }
    );
  };

  if (!storeId) {
    return (
      <Box p={20}>
        <Text>Please select a store first</Text>
      </Box>
    );
  }

  return (
    <Box p={20}>
      {loading ? null : (
        <div>
          <SimpleGrid cols={{ base: 1, sm: 2, lg: 5 }} mb={20}>
            {selectedArtworks.map((artwork, index) => (
              <Card key={index} shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src={artwork.artwork}
                    alt={`Product ${index + 1}`}
                    height={100}
                    style={{ objectFit: "contain" }}
                  />
                </Card.Section>
                <Group justify="space-between" mt="md" mb="xs">
                  <Text size="sm" lineClamp={2}>
                    {artwork.prompt || `Product ${index + 1}`}
                  </Text>
                  <Switch
                    checked={savedProducts[index] || false}
                    onChange={() => onSaveChange(index, !savedProducts[index])}
                    label="Save"
                  />
                </Group>
              </Card>
            ))}
          </SimpleGrid>
          <MultiSelect
            data={supplierOptions}
            placeholder="Select supplier"
            value={suppliers}
            onChange={(value) => setSuppliers(value)}
          />
          <Button onClick={createProductTemplate} mt={10}>
            Create Product Templates
          </Button>
        </div>
      )}
      {loading && (
        <Flex direction="column" justify="center" align="center" h="100%">
          {loadingTemplates.length > 0 ? (
            <RingProgress
              size={100}
              sections={[{
                value: loadingTemplates.length / getSelectedProducts().length * 100,
                color: loadingTemplates.length / getSelectedProducts().length === 1 ? "green" : "blue"
              }]}
            />
          ) : (
            <Loader size={100} color="blue" />
          )}
          <Text fz={30} ml={20} c="black" size="lg">
            {loadingText}
          </Text>
        </Flex>
      )}
    </Box>
  );
};
