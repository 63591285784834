import { useFilterContext } from "src/contexts";
import { FilterPills } from "src/modules/filter/components/filter-pill";
import { FILTER_CONFIGS } from "src/modules/filter/config";
import { useAuth0 } from "@auth0/auth0-react";
import { filterProducts } from "src/services/automation.service";
import { StoreBasicDataType } from "src/modules/store-creator/types/store-data";
import { ProductFilterType } from "src/modules/filter/types";
import { useInitialFilters } from "src/modules/filter/utils/use-initial-filters";

interface ProductsFilterPillsProps {
    setFilteredData: (data: any[]) => void;
}

export const ProductsFilterPills = ({ setFilteredData }: ProductsFilterPillsProps) => {
    const { state: filters, dispatch } = useFilterContext();
    const { getAccessTokenSilently } = useAuth0();
    const { initialProductFilters } = useInitialFilters();

    interface PersonaValue {
        id: number;
        name: string;
    }

    const isPersona = (value: any): value is PersonaValue => {
        return typeof value === 'object' && 'id' in value && 'name' in value;
    };

    const isStore = (value: any): value is StoreBasicDataType => {
        return typeof value === 'object' && 'store_id' in value && 'store_name' in value;
    };

    const isDefaultValue = (key: string, value: any): boolean => {
        const initialValue = initialProductFilters[key as keyof ProductFilterType];

        // Handle undefined/null
        if (!value && !initialValue) return true;
        if (!value || !initialValue) return false;

        // Handle objects (like persona or store)
        if (typeof value === 'object') {
            if (key === 'store') {
                return false; // Always show store filter
            }
            if (key === 'persona' && isPersona(value) && isPersona(initialValue)) {
                return !value.id || value.id === initialValue.id;
            }
            return Object.keys(value).length === 0;
        }

        // Handle numbers
        if (typeof value === 'number') {
            return value === (initialValue || 0);
        }

        // Handle strings
        if (typeof value === 'string') {
            return !value.trim() || value === (initialValue || '');
        }

        return value === initialValue;
    };

    const handleRemove = async (key: string) => {
        try {
            // Create new filters with the removed key reset to initial value
            const currentFilters = { ...filters.products };
            delete currentFilters[key as keyof ProductFilterType];

            // Update filter state immediately
            dispatch({
                type: 'SET_PRODUCTS_FILTER',
                payload: {
                    ...currentFilters,
                    [key]: initialProductFilters[key as keyof ProductFilterType]
                }
            });

            // Fetch new data with updated filters
            const token = await getAccessTokenSilently();
            const params = new URLSearchParams();
            
            // Add remaining filters to query
            Object.entries(currentFilters).forEach(([k, v]) => {
                if (!v) return;
                if (k === 'store' && (v as StoreBasicDataType).store_id) {
                    params.append('store_id', (v as StoreBasicDataType).store_id.toString());
                } else if (
                    v !== initialProductFilters[k as keyof ProductFilterType] && 
                    (typeof v === 'string' || typeof v === 'number')
                ) {
                    params.append(k, v.toString());
                }
            });

            const { data } = await filterProducts(token, params.toString());
            setFilteredData(data || []);
        } catch (error) {
            console.error('Error removing filter:', error);
        }
    };

    return (
        <FilterPills
            filters={filters.products}
            filterConfigs={FILTER_CONFIGS.products}
            onRemove={handleRemove}
            type="products"
            isDefaultValue={isDefaultValue}
        />
    );
};
