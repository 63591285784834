import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFilterContext, useStoreContext } from 'src/contexts';
import { useInitialFilters } from 'src/modules/filter/utils/use-initial-filters';
import { ArtworkFiltersType, PersonaFiltersType, ProductFilterType } from 'src/modules/filter/types';
import { StoreBasicDataType } from 'src/modules/store-creator/types/store-data';

export const AppFilterTypeHandler = () => {
  const location = useLocation();
  const { dispatch } = useFilterContext();

  const { storeId, storeName } = useStoreContext();
  const { initialPersonaFilters, initialArtworkFilters, initialProductFilters } = useInitialFilters();

  useEffect(() => {
    const path = location.pathname;
    const store = storeId && storeName ? { store_id: storeId, store_name: storeName } : undefined;

    if (path.includes('artworks')) {
      dispatch({ type: "SET_FILTER_TYPE", payload: "artworks" });
    } else if (path.includes('personas')) {
      dispatch({ type: "SET_FILTER_TYPE", payload: "personas" });
      if (store) {
        const filter: Partial<PersonaFiltersType> = { store };
        dispatch({
          type: "SET_PERSONA_FILTER",
          payload: filter
        });
      }
    } else if (path.includes('products')) {
      dispatch({ type: "SET_FILTER_TYPE", payload: "products" });
      if (store) {
        const filter: Partial<ProductFilterType> = { store };
        dispatch({
          type: "SET_PRODUCTS_FILTER",
          payload: filter
        });
      }
    }
  }, [location.pathname, dispatch, storeId, storeName]);

  return null;
};
