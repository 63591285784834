import { FC } from 'react';
import { FilterConfig } from 'src/modules/filter/types';

type FilterType = 'personas' | 'products' | 'artworks';

export const FilterPills: FC<{
    filters: Record<string, any>;
    filterConfigs: FilterConfig[];
    onRemove: (key: string) => void;
    type: FilterType;
    isDefaultValue: (key: string, value: any) => boolean;
}> = ({ filters, filterConfigs, onRemove, type, isDefaultValue }) => {

    return (
        <>
            {filterConfigs.map(({ key, displayName, pillValue }) => {
                const value = filters[key];
                
                // Don't render pill if value is empty or undefined
                if (!value) return null;

                // Don't render pill if value matches initial/default value (except for store)
                if (isDefaultValue(key, value)) return null;

                const displayValue = pillValue ? pillValue(value) : value;

                // Don't render pill if displayValue is empty
                if (!displayValue) return null;

                return (
                    <div
                        key={key}
                        className="px-2 py-1 items-center flex flex-row w-fit gap-3 text-sm font-normal rounded text-black"
                        style={{ border: '1px solid black' }}
                    >
                        <span className="text-nowrap">
                            {displayName}: {displayValue}
                        </span>
                        <span onClick={() => onRemove(key)} className="cursor-pointer">
                            X
                        </span>
                    </div>
                );
            })}
        </>
    );
};
