import {
  LllmSection,
  ProfileImage,
  ProfileUsernameField,
  SettingsButton,
} from "src/modules/settings";
import { useUserContext } from "src/contexts";

export const ProfileInfo = () => {
  const { userData } = useUserContext();

  return (
    <div className="flex-grow flex flex-col md:justify-between gap-10 p-10 xl:p-16">
      <div className="flex flex-col md:justify-between gap-5 lg:gap-10">
        <ProfileImage />

        <div className="flex flex-col gap-2 max-[900px]:w-max">
          <ProfileUsernameField />
          <span>{userData.email || "Email"}</span>
        </div>

        <div className="flex flex-col gap-5">
          <SettingsButton>Change password</SettingsButton>
          <SettingsButton>Export account data</SettingsButton>
        </div>
      </div>

      <div className="flex flex-col md:justify-between gap-5 lg:gap-10">
        <LllmSection />

        <SettingsButton>Delete account</SettingsButton>
      </div>
    </div>
  );
};
