import { FC, PropsWithChildren } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Drawer } from "@mantine/core";
import { MenuCloseIcon } from "src/modules/products-screens/components/menu/components/menu-close-icon";

import logoImg from "src/assets/icons/logo-black.png";

interface PlanInfoModalProps {
  title?: string;
  opened: boolean;
  onClose: () => void;
}

export const PlanInfoModal: FC<PlanInfoModalProps & PropsWithChildren> = ({
  title,
  opened,
  onClose,
  children,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1100px)");

  const sizeMap = {
    mobile: "100vw",
    tablet: "45vw",
    default: "35vw",
  };

  const getModalWidth = () => {
    if (isMobile) return sizeMap.mobile;
    if (isTablet) return sizeMap.tablet;

    return sizeMap.default;
  };

  return (
    <Drawer
      position="right"
      opened={opened}
      onClose={onClose}
      returnFocus={false}
      withCloseButton={false}
      size={getModalWidth()}
      overlayProps={{ backgroundOpacity: 0, blur: 0 }}
      withOverlay={false}
      bg="none"
      styles={{
        content: {
          background: "none",
          boxShadow: "none",
        },
        body: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        },
      }}
    >
      <div
        className="flex items-center bg-[#fefcf0] justify-end h-[65px] sm:px-10 md:px-14 lg:px-20 xl:px-[100px] flex-none"
        style={{
          borderBottom: "1px solid black",
          marginLeft: isMobile ? 0 : 20,
        }}
      >
        <div className="fixed right-[50vw] bg-[#fefcf0] translate-x-1/2 flex justify-center items-center">
          <img src={logoImg} alt="logo" className="w-16 sm:w-20 mx-auto" />
        </div>

        <span className="hidden sm:inline text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-zinc-400">
          {title}
        </span>
      </div>

      <div
        className="bg-[#fefcf0] relative flex-grow flex flex-col px-10 lg:px-14 xl:px-[100px] py-[30%] gap-5 md:gap-10 h-full justify-between overflow-auto select-none"
        style={{ marginLeft: isMobile ? 0 : 20 }}
      >
        <div
          onClick={onClose}
          className="absolute top-5 right-5 self-end cursor-pointer"
          style={{ display: isMobile ? "block" : "none" }}
        >
          <MenuCloseIcon opened />
        </div>

        {children}
      </div>

      {!isMobile && (
        <div className="h-screen w-[1px] bg-black absolute left-5 top-0">
          <div
            onClick={onClose}
            className="absolute top-1/2 translate-y-1/2 -translate-x-1/2 left-0 h-9 w-9 flex justify-center items-center bg-[#fefcf0] cursor-pointer border border-solid border-black rounded-lg z-10"
          >
            <MenuCloseIcon opened />
          </div>
        </div>
      )}
    </Drawer>
  );
};
