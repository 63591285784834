import { createContext, useContext, useReducer, ReactNode, useEffect } from 'react';
import { useStoreContext } from 'src/contexts/storeContext';
import { PersonaFiltersType, ArtworkFiltersType, FilterType, ProductFilterType } from 'src/modules/filter/types';
import { useInitialFilters } from 'src/modules/filter/utils/use-initial-filters';

interface FilterState {
  personas: PersonaFiltersType;
  artworks: ArtworkFiltersType;
  products: ProductFilterType;
  activeFilterType: FilterType | null;
}

type FilterAction =
  | { type: "SET_PERSONA_FILTER"; payload: Partial<PersonaFiltersType> }
  | { type: "SET_ARTWORK_FILTER"; payload: Partial<ArtworkFiltersType> }
  | { type: "SET_PRODUCTS_FILTER"; payload: Partial<ProductFilterType> }
  | { type: "SET_FILTER_TYPE"; payload: FilterType }
  | { type: "RESET_FILTERS" };

const createFilterReducer = (initialState: FilterState) => 
  (state: FilterState, action: FilterAction): FilterState => {
    switch (action.type) {
      case "SET_PERSONA_FILTER":
        return {
          ...state,
          personas: {
            ...state.personas,
            ...action.payload
          }
        };
      case "SET_ARTWORK_FILTER":
        return {
          ...state,
          artworks: {
            ...state.artworks,
            ...action.payload
          }
        };
      case "SET_PRODUCTS_FILTER":
        return {
          ...state,
          products: {
            ...state.products,
            ...action.payload
          }
        };
      case "SET_FILTER_TYPE":
        return {
          ...state,
          activeFilterType: action.payload
        };
      case "RESET_FILTERS":
        return {
          ...initialState,
          activeFilterType: state.activeFilterType
        };
      default:
        return state;
    }
  };

const FilterContext = createContext<{
  state: FilterState;
  dispatch: React.Dispatch<FilterAction>;
}>({
  state: {
    personas: {} as PersonaFiltersType,
    artworks: {} as ArtworkFiltersType,
    products: {} as ProductFilterType,
    activeFilterType: null
  },
  dispatch: () => null
});

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const { initialPersonaFilters, initialArtworkFilters, initialProductFilters } = useInitialFilters();
  const { storeId } = useStoreContext();
  
  const initialFilterState: FilterState = {
    personas: initialPersonaFilters,
    artworks: initialArtworkFilters,
    products: initialProductFilters,
    activeFilterType: null
  };

  const filterReducer = createFilterReducer(initialFilterState);
  const [state, dispatch] = useReducer(filterReducer, initialFilterState);

  // Update filters when store changes or when navigating to pages
  useEffect(() => {
    if (storeId && initialPersonaFilters.store && initialProductFilters.store) {
      // Update persona filters if store filter is not set or different
      if (!state.personas.store?.store_id || state.personas.store.store_id !== initialPersonaFilters.store.store_id) {
        dispatch({
          type: "SET_PERSONA_FILTER",
          payload: {
            store: initialPersonaFilters.store
          }
        });
      }

      // Update product filters if store filter is not set or different
      if (!state.products.store?.store_id || state.products.store.store_id !== initialProductFilters.store.store_id) {
        dispatch({
          type: "SET_PRODUCTS_FILTER",
          payload: {
            store: initialProductFilters.store
          }
        });
      }
    }
  }, [storeId, initialPersonaFilters.store, initialProductFilters.store]);

  // Preserve active filter type across navigation
  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes('artworks')) {
      dispatch({ type: "SET_FILTER_TYPE", payload: "artworks" });
    } else if (path.includes('personas')) {
      dispatch({ type: "SET_FILTER_TYPE", payload: "personas" });
    } else if (path.includes('products')) {
      dispatch({ type: "SET_FILTER_TYPE", payload: "products" });
    }
  }, [dispatch]);

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);
