import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Select } from "@mantine/core";
import { updateUserProfile } from "src/services/automation.service";
import { useUserContext } from "src/contexts";

const llmOptions = {
  "OpenAI - O1": "o1",
  "OpenAI - O1 Mini": "o1-mini",
  "OpenAI - GPT-4O": "gpt-4o",
  "OpenAI - GPT-4O Mini": "gpt-4o-mini",
  "Claude - 3.5 Sonnet": "claude-3-5-sonnet-20241022",
  "Claude - 3.5 Haiku": "claude-3-5-haiku-20241022",
};

type LlmOptionsType = keyof typeof llmOptions;

export const LllmSection = () => {
  const { userData } = useUserContext();
  const [selectedLlm, setSelectedLlm] = useState<string>(userData.llm_choice);

  console.log("selectedLlm", selectedLlm);

  const { getAccessTokenSilently } = useAuth0();

  const llmVariants = Object.keys(llmOptions) as LlmOptionsType[];

  const handleChange = async (name: string | null) => {
    const updatedLlm = llmOptions[name as LlmOptionsType];

    setSelectedLlm(updatedLlm);

    const token = await getAccessTokenSilently();
    await updateUserProfile(token, { llm_choice: updatedLlm });
  };

  const getLlmName = (value: string) => {
    const llm = Object.entries(llmOptions).find(([key, val]) => val === value);
    return llm ? llm[0] : null;
  };

  return (
    <div className="flex flex-col gap-5 md:gap-10 select-none">
      <h3 className="text-2xl font-medium leading-none m-0">LLM Choice</h3>

      <div className="flex justify-between gap-5">
        <Select
          w={250}
          placeholder="LLM"
          data={llmVariants}
          defaultValue={getLlmName(selectedLlm)}
          onChange={handleChange}
          styles={{
            input: {
              borderRadius: 0,
              borderColor: "#939598",
              fontWeight: 500,
              fontSize: 16,
              userSelect: "none",
            },
          }}
        />
      </div>
    </div>
  );
};
