import { useFilterContext } from "src/contexts";
import { FilterPills } from "src/modules/filter/components/filter-pill";
import { FILTER_CONFIGS } from "src/modules/filter/config";
import { useAuth0 } from "@auth0/auth0-react";
import { filterArtworks } from "src/services/automation.service";
import { ArtworkFiltersType } from "src/modules/filter/types";
import { useInitialFilters } from "src/modules/filter/utils/use-initial-filters";

interface ArtworksFilterPillsProps {
    setFilteredData: (data: any[]) => void;
}

interface PersonaType {
    id: number;
    name: string;
}

export const ArtworksFilterPills = ({ setFilteredData }: ArtworksFilterPillsProps) => {
    const { state: filters, dispatch } = useFilterContext();
    const { getAccessTokenSilently } = useAuth0();
    const { initialArtworkFilters } = useInitialFilters();

    const isPersona = (value: any): value is PersonaType => {
        return typeof value === 'object' && 'id' in value && 'name' in value;
    };

    const isDefaultValue = (key: string, value: any): boolean => {
        const initialValue = initialArtworkFilters[key as keyof ArtworkFiltersType];

        // Handle undefined/null
        if (!value && !initialValue) return true;
        if (!value || !initialValue) return false;

        // Handle objects (like persona or store)
        if (typeof value === 'object') {
            if (key === 'store') {
                return false; // Always show store filter
            }
            if (key === 'persona' && isPersona(value) && isPersona(initialValue)) {
                return !value.id || value.id === initialValue.id;
            }
            return Object.keys(value).length === 0;
        }

        // Handle numbers
        if (typeof value === 'number') {
            return value === (initialValue || 0);
        }

        // Handle strings
        if (typeof value === 'string') {
            return !value.trim() || value === (initialValue || '');
        }

        return value === initialValue;
    };

    const handleRemove = async (key: string) => {
        try {
            // Create new filters with the removed key reset to initial value
            const currentFilters = { ...filters.artworks };
            delete currentFilters[key as keyof ArtworkFiltersType];

            // Update filter state immediately
            dispatch({
                type: 'SET_ARTWORK_FILTER',
                payload: {
                    ...currentFilters,
                    [key]: initialArtworkFilters[key as keyof ArtworkFiltersType]
                }
            });

            // Fetch new data with updated filters
            const token = await getAccessTokenSilently();
            const params = new URLSearchParams();
            
            // Add remaining filters to query
            Object.entries(currentFilters).forEach(([k, v]) => {
                if (!v) return;
                if (k === 'persona' && typeof v === 'object' && 'id' in v) {
                    const persona = v as PersonaType;
                    if (persona.id) {
                        params.append('persona_id', persona.id.toString());
                    }
                } else if (
                    v !== initialArtworkFilters[k as keyof ArtworkFiltersType] && 
                    (typeof v === 'string' || typeof v === 'number')
                ) {
                    params.append(k, v.toString());
                }
            });

            const { data } = await filterArtworks(token, params.toString());
            setFilteredData(data || []);
        } catch (error) {
            console.error('Error removing filter:', error);
        }
    };

    return (
        <FilterPills
            filters={filters.artworks}
            filterConfigs={FILTER_CONFIGS.artworks}
            onRemove={handleRemove}
            type="artworks"
            isDefaultValue={isDefaultValue}
        />
    );
};
