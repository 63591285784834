import { useMediaQuery } from "@mantine/hooks";
import { Drawer } from "@mantine/core";
import { MenuCloseIcon } from "src/modules/products-screens/components/menu/components/menu-close-icon";
import { ProfileInfo } from "./profile-info";
import { useUserContext } from "src/contexts";

export const ProfileSidebar = () => {
  const { profileInfoOpened, setProfileInfoOpened } = useUserContext();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const closeSidebar = () => {
    setProfileInfoOpened(false);
  };

  return (
    <Drawer
      position="right"
      opened={profileInfoOpened}
      onClose={closeSidebar}
      returnFocus={false}
      withCloseButton={false}
      size={isMobile ? "100vw" : "37vw"}
      overlayProps={{ backgroundOpacity: 0, blur: 0 }}
      withOverlay={false}
      lockScroll={false}
      bg="none"
      styles={{
        content: {
          background: "none",
          boxShadow: "none",
        },
        body: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        },
      }}
    >
      <div
        className="flex items-center bg-[#fefcf0] h-[65px] px-10 md:px-16 flex-none"
        style={{
          borderBottom: "1px solid black",
          marginLeft: isMobile ? 0 : 20,
        }}
      >
        <span className="text-3xl font-medium text-zinc-400">Profile</span>
      </div>

      <div
        className="bg-[#fefcf0] relative flex-grow flex flex-col h-full justify-between overflow-auto"
        style={{ marginLeft: isMobile ? 0 : 20 }}
      >
        <div
          onClick={closeSidebar}
          className="absolute top-5 right-5 self-end cursor-pointer"
          style={{ display: isMobile ? "block" : "none" }}
        >
          <MenuCloseIcon opened />
        </div>

        <ProfileInfo />
      </div>

      {!isMobile && (
        <div className="h-screen w-[1px] bg-black absolute left-5 top-0">
          <div
            onClick={closeSidebar}
            className="absolute top-1/2 translate-y-1/2 -translate-x-1/2 left-0 h-9 w-9 flex justify-center items-center bg-[#fefcf0] cursor-pointer border border-solid border-black rounded-lg z-10"
          >
            <MenuCloseIcon opened />
          </div>
        </div>
      )}
    </Drawer>
  );
};
