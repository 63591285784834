import { PageLayout } from 'src/components/page-layout';
import { Loader } from '@mantine/core';
import { ChatLoading } from 'src/components/ChatLoading';

export const FullPageLoader = () => {
  return (
    <PageLayout>
      <div className="h-full flex justify-center items-center">
        <ChatLoading />
      </div>
    </PageLayout>
  );
};
