import { Slider } from "@mantine/core";
import { FC } from "react";

interface PlanSliderProps {
  labelLeft?: string;
  labelRight?: string;
  value: number;
  max: number;
}

export const PlanSlider: FC<PlanSliderProps> = ({
  labelLeft,
  labelRight,
  value,
  max,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between gap-5">
        <span>{labelLeft}</span>
        <span className="text-zinc-400 font-normal">{labelRight}</span>
      </div>

      <Slider
        value={value}
        max={max}
        size="xs"
        color="black"
        styles={{ thumb: { display: "none" } }}
      />
    </div>
  );
};
