import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { updateUserProfile } from "src/services/automation.service";
import { useUserContext } from "src/contexts";

export const ProfileUsernameField = () => {
  const { userData } = useUserContext();

  const [fieldData, setFieldData] = useState(userData.name);
  const [isEditMode, setIsEditMode] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const changeEditMode = () => {
    isEditMode && saveData();

    setIsEditMode(!isEditMode);
  };

  const saveData = async () => {
    const token = await getAccessTokenSilently();
    await updateUserProfile(token, { name: fieldData });
  };

  return (
    <div className="h-7 flex items-center justify-between gap-10 md:gap-3">
      {isEditMode ? (
        <input
          onChange={(e) => setFieldData(e.target.value)}
          value={fieldData}
          placeholder={fieldData}
          className="px-2 -translate-x-[9px] outline-none bg-transparent border-[1px] border-solid border-zinc-300 rounded-sm placeholder:text-zinc-400 text-zinc-600"
        />
      ) : (
        <span>{fieldData}</span>
      )}

      <button
        onClick={changeEditMode}
        className="text-zinc-400 text-sm font-medium bg-inherit outline-none border-none p-0 m-0 cursor-pointer hover:text-zinc-500 transition-colors duration-300"
      >
        {isEditMode ? "Save" : "Edit"}
      </button>
    </div>
  );
};
