import { VerticalScroll } from "src/modules/blueprints";

const testData = {
  data: "22.06.2025",
  id: 654321216546511,
  credits: 3,
};

export const TransactionHistory = () => {
  return (
    <div
      className="min-h-[34vh] flex-grow flex flex-col gap-5 p-10 xl:px-16 overflow-hidden text-sm md:text-lg font-medium select-none transition-all duration-300"
      style={{
        borderTop: "1px solid black",
      }}
    >
      <h3 className="text-xl md:text-2xl font-medium leading-none m-0">History</h3>

      <VerticalScroll className="overflow-y-hidden" paddingSize={0}>
        <div className="flex flex-col gap-3 text-zinc-400 font-normal md:pr-10 overflow-x-scroll">
          {Array(20)
            .fill(testData)
            .map(({ data, id, credits }, index) => (
              <div
                key={index}
                className="flex gap-5 xl:gap-10"
              >
                <span>{data}</span>
                <span className="text-nowrap">Type of operation</span>
                <span className="min-w-20 text-center flex-grow overflow-hidden truncate">
                  {id}
                </span>
                <span className="text-nowrap">{credits} credits</span>
              </div>
            ))}
        </div>
      </VerticalScroll>
    </div>
  );
};
