import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Button } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { IconWand } from "@tabler/icons-react";
import { useProducts } from "src/selected-products-provider";
import { deleteProducts } from "src/services/automation.service";

interface ProductsController {
  handleManualCreation: () => void;
  handleAutoCreation: () => void;
}

export const ProductsController: FC<ProductsController> = ({
  handleManualCreation,
  handleAutoCreation,
}) => {
  const { selectedProductIds, setSelectedProductIds } = useProducts();

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

    const handleDeleteSelected = async () => {
        const token = await getAccessTokenSilently();
        await deleteProducts(
          token,
          selectedProductIds.map((id: string) => Number(id))
        );
        setSelectedProductIds([]);
        await queryClient.invalidateQueries({ queryKey: ["products"] });
      };

  return (
    <Flex columnGap={20} justify="space-between" wrap="wrap">
      <Flex columnGap={20} wrap="wrap">
        {selectedProductIds.length !== 0 && (
          <Button p={0} variant="transparent" onClick={handleDeleteSelected}>
            Delete selected
          </Button>
        )}

        <Button p={0} variant="transparent" onClick={handleManualCreation}>
          Manual creation of Products
        </Button>
      </Flex>

      <Button variant="outline" c="black" onClick={handleAutoCreation}>
        <Flex gap={5} align="center">
          <IconWand height={20} />
          Auto create Products
        </Flex>
      </Button>
    </Flex>
  );
};
