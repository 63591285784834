import { useStoreContext } from "src/contexts";
import { PersonaFiltersType, ArtworkFiltersType, ProductFilterType } from "src/modules/filter/types";
import { useMemo } from "react";

export const useInitialFilters = () => {
  const { storeId, storeName } = useStoreContext();

  const defaultStore = useMemo(() => {
    if (!storeId) return undefined;
    return {
      store_id: storeId,
      store_name: storeName || ""
    };
  }, [storeId, storeName]);

  const initialPersonaFilters = useMemo<PersonaFiltersType>(() => ({
    min_age: 0,
    max_age: 0,
    country: "",
    target_audience: "",
    store: defaultStore,
    start_date: "",
    end_date: "",
  }), [defaultStore]);

  const initialArtworkFilters = useMemo<ArtworkFiltersType>(() => ({
    persona: {
      id: 0,
      name: ""
    },
    model_used: "",
    start_date: "",
    end_date: "",
  }), []);

  const initialProductFilters = useMemo<ProductFilterType>(() => ({
    collection_name: "",
    theme: "",
    persona: {
      id: 0,
      name: "",
    },
    category: "",
    min_price: 0,
    max_price: 0,
    is_synced: false,
    store: defaultStore,
    start_date: "",
    end_date: "",
  }), [defaultStore]);

  return useMemo(() => ({
    initialPersonaFilters,
    initialArtworkFilters,
    initialProductFilters
  }), [initialPersonaFilters, initialArtworkFilters, initialProductFilters]);
};
