import { motion } from "framer-motion";

export const InitialMessage = () => {
  return (
    <motion.div
      className="flex flex-col justify-center gap-5 md:gap-10 text-gray-400"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 1 }}
    >
      <span className="text-2xl">
        Unlock your full potential by purchasing credits to fuel your growth.
      </span>

      <motion.span
        initial={{ opacity: 0, y: 50, color: "rgb(156, 163, 175)" }}
        animate={{ opacity: 1, y: 0, color: "rgb(0, 0, 0)" }}
        transition={{ delay: 1, duration: 0.5 }}
        className="text-2xl"
      >
        Get the resources you need to scale and succeed
      </motion.span>
    </motion.div>
  );
};
