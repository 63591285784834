import { useEffect, useState } from 'react';
import { AnimatedText } from 'src/modules/landing-screens';
import icon from '../assets/icons/icons8-bot-96.png';
export const ChatLoading = ({
  isIcon = false,
  isText = false,
  isWhite = false,
  isDots = true,
}: {
  isIcon?: boolean;
  isText?: boolean;
  isWhite?: boolean;
  isDots?: boolean;
}) => {
  const [currentPhrase, setCurrentPhrase] = useState('Thinking...');
  const phrases = [
    'Thinking...',
    'Fetching data...',
    'Exploring the universe...',
    'Analyzing...',
    'Connecting to deep space...',
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPhrase(phrases[Math.floor(Math.random() * phrases.length)]);
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="flex flex-col text-sm items-center">
      {isText && (
        <AnimatedText
          delay={0}
          key={currentPhrase}
          textContent={currentPhrase}
        />
      )}
      <div className="flex flex-row gap-2 mt-2 justify-center items-center">
        {isIcon && <img src={icon} className="w-10 h-10" />}
        {isDots &&
          (isWhite ? (
            <div className="flex justify-center items-end">
              <div className="dot bg-white"></div>
              <div className="dot bg-white"></div>
              <div className="dot bg-white"></div>
            </div>
          ) : (
            <div className="flex justify-center items-end">
              <div className="dot bg-gray-800"></div>
              <div className="dot bg-gray-800"></div>
              <div className="dot bg-gray-800"></div>
            </div>
          ))}
      </div>
      <style>
        {`
            .dot {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 3px;
              animation: wave 1.3s linear infinite;
            }
            .dot:nth-child(2) {
              animation-delay: -1.1s;
            }
            .dot:nth-child(3) {
              animation-delay: -0.9s;
            }
            @keyframes wave {
              0%, 60%, 100% {
                transform: translateY(5px);
              }
              30% {
                transform: translateY(-5px);
              }
            }
          `}
      </style>
    </div>
  );
};
