import { FC, PropsWithChildren } from "react";

interface SettingsButtonProps {
  onClick?: () => void;
}

export const SettingsButton: FC<SettingsButtonProps & PropsWithChildren> = ({
  onClick,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      className="w-max text-zinc-400 text-sm md:text-lg font-normal bg-inherit outline-none border-none p-0 m-0 cursor-pointer hover:text-zinc-500 transition-colors duration-300"
    >
      {children}
    </button>
  );
};
