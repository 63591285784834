import { memo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from 'src/components/page-layout';
import { useStoreContext } from 'src/contexts';
import { MyCollection, DiscoverBlock } from 'src/modules/blueprints';
import WelcomeMessage from 'src/modules/products-screens/products-chat/components/welcome-message';
import { Loader } from '@mantine/core';
import { ChatLoading } from 'src/components/ChatLoading';

type Tab = 'collection' | 'discover';

export const BlueprintsPage = memo(() => {
  const [activeTab, setActiveTab] = useState<Tab>('collection');
  const { storeFullData, isLoading } = useStoreContext();

  const { user } = useAuth0();

  if (isLoading || !storeFullData?.blueprints) {
    return (
      <PageLayout>
        <div className="flex h-full justify-center items-center">
          <ChatLoading />
        </div>
      </PageLayout>
    );
  }

  const blueprintsData = storeFullData.blueprints.blueprint_data || [];

  if (blueprintsData.length === 0) {
    return (
      <PageLayout>
        <div className="h-full flex items-center p-10">
          <WelcomeMessage
            userName={user?.nickname || 'User'}
            message="It looks like you haven't created any blueprints yet. Start by creating your first blueprint to see it displayed here."
          />
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className="flex flex-col md:flex-row h-full overflow-hidden w-full md:pr-10 select-none">
        <MyCollection
          blueprintsData={blueprintsData}
          setActiveTab={setActiveTab}
          className={`w-full px-8 md:w-[30%] md:px-5 md:flex-none lg:px-10 ${
            activeTab === 'discover' && 'hidden md:block'
          }`}
        />
        <DiscoverBlock
          blueprintsData={blueprintsData}
          setActiveTab={setActiveTab}
          className={`w-full px-8 md:flex-grow md:px-8 lg:px-16 ${
            activeTab === 'collection' && 'hidden md:block'
          }`}
        />
      </div>
    </PageLayout>
  );
});
