import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createPaymentCheckoutSession,
  getUserProfileData,
} from 'src/services/automation.service';
import { PageLayout } from 'src/components/page-layout';
import {
  CreditsOption,
  FailedMessage,
  InitialMessage,
  PlanInfoModal,
  PlanSection,
  PlanSlider,
  SuccessMessage,
} from 'src/modules/plans';
import { creditTypes } from 'src/modules/plans/data';
import { usePlansContentWidth } from 'src/modules/plans/hooks';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@mantine/core';
import { UserProfile } from 'src/models/user-profile';
import { ChatLoading } from 'src/components/ChatLoading';

type InfoMessageType = 'initial' | 'success' | 'fail';

const messages = {
  initial: {
    infoTitle: '',
    MessageElem: InitialMessage,
  },
  fail: {
    infoTitle: 'Failed',
    MessageElem: FailedMessage,
  },
  success: {
    infoTitle: 'Success !',
    MessageElem: SuccessMessage,
  },
};

export const PlansPage = () => {
  const [isInfoModalOpened, setIsInfoModalOpened] = useState(false);
  const [infoMessageType, setInfoMessageType] =
    useState<InfoMessageType>('initial');

  const contentWidth = usePlansContentWidth(isInfoModalOpened);
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: userDataQuery,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['user-data'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getUserProfileData(token);
    },
  });

  const userData = useMemo(() => {
    // Return null instead of empty array when data isn't ready
    return isSuccess ? userDataQuery?.data : null;
  }, [userDataQuery?.data, isSuccess]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    query.get('success') && setInfoMessageType('success');
    query.get('canceled') && setInfoMessageType('fail');

    setTimeout(() => setIsInfoModalOpened(true), 1000);
  }, []);

  const { MessageElem, infoTitle } = messages[infoMessageType];

  const createCheckoutSession = async (quantity: number) => {
    const token = await getAccessTokenSilently();

    const res = await createPaymentCheckoutSession(token, quantity);

    if (res.data?.url && res.data?.sessionId) {
      window.location.href = res.data.url;
    }

    if (res.error) setInfoMessageType('fail');
  };

  if (isLoading || !userData) {
    return (
      <PageLayout>
        <div className="h-full w-full flex justify-center items-center">
          <ChatLoading />
        </div>
      </PageLayout>
    );
  }

  const creditBalance = userData.credit_balance ?? 0;

  return (
    <PageLayout>
      <PlanInfoModal
        title={infoTitle}
        opened={isInfoModalOpened}
        onClose={() => setIsInfoModalOpened(false)}
      >
        <MessageElem />
      </PlanInfoModal>

      <div
        className="h-full flex-grow overflow-scroll text-lg font-medium select-none transition-all duration-300"
        style={{
          width: contentWidth,
        }}
      >
        <PlanSection>
          <h3 className="text-2xl font-medium leading-none m-0">
            Current Credits
          </h3>

          <PlanSlider
            labelLeft={String(creditBalance)}
            value={creditBalance}
            max={creditBalance * 1.75}
          />
        </PlanSection>

        <PlanSection>
          <PlanSlider
            labelLeft="Daily Average Usage"
            labelRight="15 credits"
            value={356}
            max={500}
          />

          <p className="text-zinc-400 font-normal m-0">
            At your current usage rate, credits will last at
            <span className="text-black"> 01.02.2025</span>
          </p>
        </PlanSection>

        <PlanSection withBorder={false} className="gap-10">
          <h3 className="text-2xl font-medium leading-none m-0">Add Credits</h3>

          <div className="flex gap-9 flex-wrap justify-evenly">
            {creditTypes.map((values, i) => (
              <CreditsOption
                key={i}
                onClick={() => createCheckoutSession(values.quantity)}
                {...values}
              />
            ))}
          </div>
        </PlanSection>
      </div>
    </PageLayout>
  );
};
