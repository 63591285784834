import { FC, useState } from "react";
import { Tooltip } from "@mantine/core";

interface TokenFieldProps {
  label: string;
  token: string;
}

export const TokenField: FC<TokenFieldProps> = ({ label, token }) => {
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipOpened(true);
      setTimeout(() => setTooltipOpened(false), 1000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <span className="font-medium">{label}: </span>
      <span
        onClick={() => handleCopy(token)}
        className="cursor-pointer hover:text-zinc-600 flex items-center gap-1"
      >
        <Tooltip
          label="Copied"
          opened={tooltipOpened}
          bg="#909090"
          position="top-start"
        >
          <span>{token}</span>
        </Tooltip>
      </span>
    </div>
  );
};
