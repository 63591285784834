import React, { useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Box,
  Text,
  Card,
  Checkbox,
  Badge,
  SimpleGrid,
  Anchor,
  Loader,
  Table,
  TableData,
  ScrollArea,
} from '@mantine/core';
import {
  getGoogleTrendsQueryData,
  getAudienceGroups,
} from 'src/services/automation.service';
import { LineChart } from '@mantine/charts';
import { ChatLoading } from '../ChatLoading';

interface AnalysisViewProps {
  trends: any[];
  resetStepper: () => void;
  nextStep: (selectedCountry: string) => void;
  selectedAudienceGroups: any[];
  setSelectedAudienceGroups: (audienceGroups: any[]) => void;
  selectedCountry: string;
}

const formatRelatedRising = (data: any) => {
  if (!data) {
    return null;
  }
  const tableData: TableData = {
    caption: '',
    head: ['Name', 'Value'],
    body: data.map((query: any) => {
      const value =
        query.value === 'Breakout'
          ? query.extracted_value.toString() + '% (Breakout)'
          : query.extracted_value + '/100';
      const name = query.query
        ? query.query
        : query.topic.title + ' (' + query.topic.type + ')';
      return [name, value];
    }),
  };
  return tableData;
};

const formatAudienceGroups = (data: any) => {
  if (!data) {
    return null;
  }

  const tableData: TableData = {
    caption: '',
    head: [
      'Name',
      'Age Range',
      'Description',
      'Identified Queries',
      'Liked Context',
    ],
    body: data.map((group: any) => {
      const identified_queries = group.group_identified_queries
        ? group.group_identified_queries.toString()
        : 'undefined';
      const liked_context = group.group_liked_context
        ? JSON.stringify(group.group_liked_context)
        : 'undefined';
      const age_range = group.group_age_range
        ? group.group_age_range
        : 'undefined';
      const description = group.group_description
        ? group.group_description
        : 'undefined';
      const name = group.group_name ? group.group_name : 'undefined';

      return [name, age_range, description, identified_queries, liked_context];
    }),
  };

  return tableData;
};

export const AnalysisView: React.FC<AnalysisViewProps> = ({
  trends,
  resetStepper,
  selectedAudienceGroups,
  setSelectedAudienceGroups,
  nextStep,
  selectedCountry,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const accessToken = useRef<string>();
  const [analysisDataTrends, setAnalysisDataTrends] = React.useState<any[]>([]);
  const [audienceGroups, setAudienceGroups] = React.useState<any[]>([]);

  useEffect(() => {
    let isMounted = true;
    console.log('Trends: ', trends);

    const fetchQueries = async (token: string) => {
      for (const trend of trends) {
        const query = trend.query;
        const data = await getGoogleTrendsQueryData(
          token,
          query,
          selectedCountry
        );
        console.log('Data: ', data);
        const tableDataRisingQueries = formatRelatedRising(
          data.data.related_queries.related_queries.rising
        );
        const tableDataTopQueries = formatRelatedRising(
          data.data.related_queries.related_queries.top
        );
        const tableDataRisingTopics = formatRelatedRising(
          data.data.related_topics.related_topics.rising
        );
        const tableDataTopTopics = formatRelatedRising(
          data.data.related_topics.related_topics.top
        );
        setAnalysisDataTrends((prev) => [
          ...prev,
          {
            query: trend.query,
            analysis: data.data,
            tableDataRisingQueries: tableDataRisingQueries,
            tableDataTopQueries: tableDataTopQueries,
            tableDataRisingTopics: tableDataRisingTopics,
            tableDataTopTopics: tableDataTopTopics,
          },
        ]);
        console.log(
          'Table Data: ',
          tableDataRisingQueries,
          tableDataTopQueries,
          tableDataRisingTopics,
          tableDataTopTopics
        );
        setAnalysisDataTrends((prev) => [
          ...prev,
          {
            query: trend.query,
            analysis: data.data,
            tableDataRisingQueries: tableDataRisingQueries,
            tableDataTopQueries: tableDataTopQueries,
            tableDataRisingTopics: tableDataRisingTopics,
            tableDataTopTopics: tableDataTopTopics,
          },
        ]);
        const dataaudienceGroups = await getAudienceGroups(token, data.data);
        console.log(dataaudienceGroups);
        const fetchedAudienceGroups =
          dataaudienceGroups.data.target_audience_groups;
        console.log(fetchedAudienceGroups);
        const tableDataAudienceGroups = formatAudienceGroups(
          fetchedAudienceGroups
        );
        console.log('Audience Groups: ', fetchedAudienceGroups);
        console.log('Table Data Audience Groups: ', tableDataAudienceGroups);
        setAudienceGroups((prev) => [
          ...prev,
          {
            query: trend.query,
            audienceGroups: fetchedAudienceGroups,
            tableDataAudienceGroups: tableDataAudienceGroups,
          },
        ]);
        console.log('Audience Groups: ', audienceGroups);
      }
    };

    const getAccessToken = async () => {
      if (!isMounted) {
        return;
      }
      try {
        const token = await getAccessTokenSilently();
        accessToken.current = token;
        fetchQueries(token); // Call fetchQueries here after the token is set
      } catch (e) {
        console.error(e);
      }
    };

    console.log('Fetching Queries');
    getAccessToken();

    return () => {
      isMounted = false;
    };
  }, [
    getAccessTokenSilently,
    trends,
    setAnalysisDataTrends,
    setAudienceGroups,
  ]); // Removed accessToken.current from the dependency array

  return (
    <Card mb={20} h="50%" bg="#1A1B1E">
      <div>
        <Text color="white" size="xl">
          Filtered Queries for Tshirt:
        </Text>
        <SimpleGrid cols={3} spacing="md" p={10}>
          {trends.map((query: any, i: number) => (
            <Card
              key={i}
              shadow="sm"
              padding="md"
              h="80vh"
              style={{
                backgroundColor: 'black',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
              onClick={() => {
                if (audienceGroups && audienceGroups.length > 0) {
                  if (
                    selectedAudienceGroups.findIndex(
                      (audienceGroup) => audienceGroup.query === query.query
                    ) === -1
                  ) {
                    setSelectedAudienceGroups([
                      ...selectedAudienceGroups,
                      audienceGroups[i],
                    ]);
                  } else {
                    setSelectedAudienceGroups(
                      selectedAudienceGroups.filter(
                        (audienceGroup) => audienceGroup.query !== query.query
                      )
                    );
                    console.log(
                      'Selected Audience Groups: ',
                      selectedAudienceGroups
                    );
                  }
                }
              }}
            >
              <Checkbox
                color="blue"
                label="Select"
                style={{ marginBottom: 10 }}
                checked={
                  selectedAudienceGroups &&
                  selectedAudienceGroups.findIndex(
                    (audienceGroup) => audienceGroup.query === query.query
                  ) !== -1
                }
                onChange={() => {}}
              />
              <ScrollArea>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Anchor href={query.google_trends_link} target="_blank">
                    <Text color="white">{query.query}</Text>
                  </Anchor>
                  <Badge color="#f3fc74" variant="light">
                    Traffic: {query.traffic}
                  </Badge>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    padding: 20,
                  }}
                >
                  {audienceGroups &&
                  audienceGroups[i] &&
                  audienceGroups[i].tableDataAudienceGroups ? (
                    <Card
                      style={{
                        padding: 40,
                        backgroundColor: 'white',
                        height: 400,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text color="black" size="xl">
                        Audience Groups
                      </Text>
                      <ScrollArea style={{ height: 300, width: '100%' }}>
                        <Table
                          stickyHeader
                          data={audienceGroups[i].tableDataAudienceGroups}
                        />
                      </ScrollArea>
                    </Card>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <ChatLoading />
                      <Text color="white">Loading Audience Groups...</Text>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    padding: 20,
                  }}
                >
                  {analysisDataTrends &&
                  analysisDataTrends[i] &&
                  analysisDataTrends[i].analysis ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <LineChart
                        h={300}
                        data={
                          analysisDataTrends[i].analysis.interest
                            .interest_over_time
                        }
                        style={{ height: 100, width: '100%', padding: 20 }}
                        series={[{ name: query.query, color: 'blue' }]}
                        dataKey="date"
                      />
                      {analysisDataTrends[i].tableDataRisingQueries && (
                        <Card
                          style={{
                            padding: 40,
                            backgroundColor: 'white',
                            height: 400,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Text color="black" size="xl">
                            Related Queries Rising
                          </Text>
                          <ScrollArea style={{ height: 300, width: '100%' }}>
                            <Table
                              stickyHeader
                              data={
                                analysisDataTrends[i].tableDataRisingQueries
                              }
                            />
                          </ScrollArea>
                        </Card>
                      )}
                      {analysisDataTrends[i].tableDataTopQueries && (
                        <Card
                          style={{
                            padding: 40,
                            backgroundColor: 'white',
                            height: 400,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Text color="black" size="xl">
                            Related Queries Top
                          </Text>
                          <ScrollArea style={{ height: 300, width: '100%' }}>
                            <Table
                              stickyHeader
                              data={analysisDataTrends[i].tableDataTopQueries}
                            />
                          </ScrollArea>
                        </Card>
                      )}
                      {analysisDataTrends[i].tableDataRisingTopics && (
                        <Card
                          style={{
                            padding: 40,
                            backgroundColor: 'white',
                            height: 400,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Text color="black" size="xl">
                            Related Topics Rising
                          </Text>
                          <ScrollArea style={{ height: 300, width: '100%' }}>
                            <Table
                              stickyHeader
                              data={analysisDataTrends[i].tableDataRisingTopics}
                            />
                          </ScrollArea>
                        </Card>
                      )}
                      {analysisDataTrends[i].tableDataTopTopics && (
                        <Card
                          style={{
                            padding: 40,
                            backgroundColor: 'white',
                            height: 400,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Text color="black" size="xl">
                            Related Topics Top
                          </Text>
                          <ScrollArea style={{ height: 300, width: '100%' }}>
                            <Table
                              stickyHeader
                              data={analysisDataTrends[i].tableDataTopTopics}
                            />
                          </ScrollArea>
                        </Card>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <ChatLoading />
                      <Text color="white">Loading...</Text>
                    </div>
                  )}
                </div>
              </ScrollArea>
            </Card>
          ))}
        </SimpleGrid>
      </div>
      {/* Footer */}
      <Box pos="sticky" bottom={0} bg="#1A1B1E">
        <Button
          onClick={() => nextStep(selectedCountry)}
          fullWidth
          size="md"
          disabled={selectedAudienceGroups.length === 0}
        >
          Confirm Trend Selection
        </Button>
      </Box>
    </Card>
  );
};

export default AnalysisView;
